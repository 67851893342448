import React from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

function TableWithPagination(props) {
  const { columns, rows, rowDisplayMethod, cursorPointerOnHover, hideRowPerPage } = props

  console.log('hideRowPerPage ', props)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleRowClick = (event, index) => {
    props.onRowClick(event, index)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div>
      <Paper style={{ height: '100%', width: '100%' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return(
                  <TableRow style={{ cursor: cursorPointerOnHover ===true ?'pointer': '', backgroundColor: row.backgroundColor }} hover role="checkbox" tabIndex={-1} key={row.id} onClick={event => handleRowClick(event, row.id)}>
                    { rowDisplayMethod(row, index) }
                  </TableRow>
                )
              })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={ hideRowPerPage === true ? [] : [10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={ rowsPerPage }
            page={ page }
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Nombre par page"
          />
        </Paper>
    </div>
  )
}
export default TableWithPagination