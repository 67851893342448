import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Form from 'react-validation/build/form';
import Typography from '@mui/material/Typography';

import InputText from '../components/InputText'
import InputPassword from '../components/InputPassword'

import AuthService from '../services/authService';

export default class Register extends Component {

  constructor(props) {
    super(props)
    this.handleChangeUserName = this.handleChangeUserName.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleRegister = this.handleRegister.bind(this)
    this.handleChangeConfirmPassword = this.handleChangeConfirmPassword.bind(this)
    this.state = {
      username: '',
      email: '',
      password:'',
      confirmPassword: '',
      loading: false,
      message: ''
    }
  }

  handleChangeUserName(e) {
    this.setState({
      username: e.target.value
    })
  }

  handleChangeEmail(e) {
    this.setState({
      email: e.target.value
    })
  }

  handleChangePassword(e) {
    this.setState({
      password: e.target.value
    })
  }

  handleChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value
    })
  }

  handleRegister(e) {
    e.preventDefault();
    const { password, confirmPassword } = this.state
    if (password === confirmPassword) {
      this.register(e)
    } else {
      this.setState({ message: 'Mot de passe différent de la confirmation'})
    }
  }

  register(e) {
    e.preventDefault();
    const { username, email, password, confirmPassword } = this.state
    this.setState({
      message: '',
      loading: true
    });
    AuthService.register(username, email, password).then(
      () => {
        this.props.history.push('/profile');
        window.location.reload();
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    )
  }

  render() {
    return (
      <div>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form onSubmit={ this.handleRegister }>
          <InputText
            required={ true }
            id="username"
            label="User name"
            name="User name"
            autoComplete="user name"
            autoFocus={ true }
            value={ this.state.username }
            onInputChange={ this.handleChangeUserName }
          />
          <InputText
            required={ true }
            id="email"
            label="Email"
            name="Email"
            value={ this.state.email }
            onInputChange={ this.handleChangeEmail }
          />
          <InputPassword
            id="password"
            label="Password"
            onInputChange={ this.handleChangePassword }
            value={ this.state.password }
          />
          <InputPassword
            id="confirmPassword"
            label="Confirm password"
            onInputChange={ this.handleChangeConfirmPassword }
            value={ this.state.confirmPpassword }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Register
          </Button>
          {this.state.message && (
              <div className='form-group'>
                <div className='alert alert-danger' role='alert'>
                  {this.state.message}
                </div>
              </div>
            )}
        </form>
      </div>
    )
  }
}
