import { Button } from '@mui/material';
import React, { Component } from 'react'
import InputText from '../components/InputText';
import Form from 'react-validation/build/form';
import InputDateTime from '../components/InputDateTime';
import EventService from '../services/eventService';
import ColorPicker from '../components/ColorPicker';

export default class EditEvent extends Component {

  constructor(props) {
    super(props)
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeColor = this.onChangeColor.bind(this);
    this.handleEditEvent = this.handleEditEvent.bind(this)
    const isNewEvent = (this.props.location.state || {}).id === undefined
    this.state = {
      isNewEvent,
      event: {
        startDate: '',
        endDate: '',
        color: '#039be5',
        title: ''
      },
      loading: false,
      message: ''
    }
  }

  onChangeDate(e) {
    const event = {...this.state.event}
    event.startDate = e.target.value
    event.endDate = e.target.value
    this.setState({ event })
  }

  onChangeTitle(e) {
    const event = {...this.state.event}
    event.title = e.target.value
    this.setState({ event })
  }

  onChangeColor(e) {
    console.log("chenge color", e)
    const event = {...this.state.event}
    event.color = e.hex
    this.setState({ event })
  }

  handleEditEvent(e) {
    e.preventDefault()
    // const { members, date, rankings } = this.state
    // const rankingIds = rankings.map((ranking) => ranking.id)
    const clubId = this.props.match.params.clubId
    if(this.state.isNewEvent) {
      EventService.create(clubId, this.state.event).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}`);
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    } else {
      const id = this.props.location.state.id
      EventService.update(clubId, id, this.state.event).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}`);
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    }
  }

  componentDidMount() {
    if(!this.state.isNewEvent) {
      const clubId = this.props.match.params.clubId
      const id = this.props.location.state.id
      EventService.get(clubId, id).then((response) => {
        this.setState({ event: response.data })
      })
    }
  }

  render() {
    const { title, startDate, color } = this.state.event
    return (
      <div>
        <Form onSubmit={ this.handleEditEvent } >
        <InputText
            required={ true }
            id="title"
            label="Titre"
            name="Titre"
            autoComplete="Titre"
            autoFocus={ true }
            value={ title || "" }
            onInputChange={ this.onChangeTitle }
          />
          <InputDateTime
            required={ true }
            id="date"
            label="Date"
            name="Date"
            autoComplete="date"
            autoFocus={ true }
            value={ startDate }
            onInputChange={ this.onChangeDate }
          />
          <ColorPicker 
            color={ color }
            onInputChange={ this.onChangeColor }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
