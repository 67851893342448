import React, { Component } from 'react'
import AdminBlockquote from '../components/AdminBlockquote'
import InputSelect from '../components/InputSelect'
import InputText from '../components/InputText';
import MemberCard from '../components/MemberCard'
import MemberService from '../services/memberService';
import { Link } from "react-router-dom";
import { Grid, Button,  Typography } from '@mui/material';

import AuthService from '../services/authService';
import { Fragment } from 'react';

const filterByStateValues = [
  { value: 'ACTIVE', text: 'Actif' },
  { value: 'INACTIVE', text: 'Inactif' },
  { value: 'ALL', text: 'Tous' },
]

export default class ClubMembers extends Component {
  constructor(props) {
    super(props)
    this.onChangeFilterPseudo = this.onChangeFilterPseudo.bind(this);
    this.onChangeFilterByState = this.onChangeFilterByState.bind(this);
    this.state = {
      members: [],
      filterPseudo: "",
      filterByState: "ACTIVE"
   }
  }
  onChangeFilterPseudo(e) {
    const filterByState = this.state.filterByState
    this.setState({
      filterPseudo: e.target.value
    });
    this.filterMembers(e.target.value, filterByState)
  }

  onChangeFilterByState(e) {
    const filterPseudo = this.state.filterPseudo
    this.setState({
      filterByState: e.target.value
    })
    this.filterMembers(filterPseudo, e.target.value)
  }

  filterMembers(filterPseudo, filterByState) {
    const { members } = this.state
    if (members.length > 0) {
      for (const member of members) {
        member.hide = false
        if (filterPseudo !== "" && filterPseudo.length >= 2 && !member.pseudo.toLowerCase().includes(filterPseudo.toLowerCase())) {
          member.hide = true
        }
        if (filterByState !== "ALL") {
          if ((filterByState === "ACTIVE" && member.member_member_club[0].state !== "A") ||
              (filterByState === "INACTIVE" && member.member_member_club[0].state !== "I")) {
                member.hide = true
          }
        }
      }
    }
    this.setState({ members })
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    MemberService.getAll(clubId).then((response) => {
      this.setState({ members: response.data })
      this.filterMembers(this.state.filterPseudo, this.state.filterByState)
    })
  }
  render() {
    const clubId = this.props.match.params.clubId
    const isAdmin = AuthService.isClubAdmin(clubId)
    
    const addMemberLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/members/edit`
    }}>
      Nouveau membre
    </Button>) : null

    const memberCardList = this.state.members.filter(member => member.hide === false)
    .map(member => (
    <Grid item md={4} sm={6} xs={12} key={ `grid_${member.id}` }> 
      <MemberCard
        key={ member.id } 
        id={ member.id } 
        pseudo={ member.pseudo } 
        clubId={ clubId } 
        tournamentCount={ member.tournamentCount }
        killCount={ member.killCount }
        hide={ member.hide } />
    </Grid>))
    return (
      <div>
        <blockquote>
          <Typography variant="h6">
            Filtres
          </Typography>
          <InputText
            required={ false }
            id="pseudo"
            label="Pseudo"
            name="Pseudo"
            autoComplete="Pseudo"
            autoFocus={ false }
            value={ this.state.filterPseudo }
            onInputChange={ this.onChangeFilterPseudo }
          />
          <InputSelect 
            id="pointCalculationMethod"
            label="Statut"
            required= { true }
            value={ this.state.filterByState || "ALL" }
            availableValues={ filterByStateValues }
            onInputChange={ this.onChangeFilterByState }
          />
        </blockquote>
        <AdminBlockquote clubId={ clubId } adminElements={(<Fragment>{ addMemberLink }</Fragment>)} />
        <Grid container spacing={3}>
        { memberCardList }
        </Grid>
      </div>
    )
  }
}
