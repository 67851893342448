import React, { Component } from 'react'
import TextField from '@mui/material/TextField';

class InputNumber extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    const isOnlyInteger = this.props.isOnlyInteger || false
    const isOnlyPositive = this.props.isOnlyPositive || false

    let value = e.target.value
    if (isOnlyInteger && value.toString().includes('.')) {
      value = parseInt(value)
      e.target.value = value
    }
    if (isOnlyPositive && value.toString().includes('-')) {
      value = value * -1
      e.target.value = value
    }
    this.props.onInputChange(e)
  }

  render() {
    const { id, label, name, required, autoComplete, autoFocus, value } = this.props
    return (
      <TextField
        variant="standard"
        margin="normal"
        fullWidth
        id={ id }
        type="number"
        label={ label }
        name={ name }
        required={ required }
        autoComplete={ autoComplete }
        autoFocus={ autoFocus }
        value={ value }
        onChange={ this.handleChange }
      />
    )
  }
}

export default InputNumber
