import React, { Component } from 'react'
import ClubScheduler from '../../components/ClubScheduler';
import ClubService from '../../services/clubService';

export default class SchedulerWidget extends Component {
  
    constructor(props) {
        super(props)
        this.fetchEvents = this.fetchEvents.bind(this);
      }

    async fetchEvents (query) {
        // TODO Une requête à chaque changement de mois, voir ce qu'il y a dans query
        console.log(query)
        const clubId = this.props.match.params.clubId
        return ClubService.getEvents(clubId, query).then((response) => {
          return new Promise((res) => {
            const events = response.data.map(event => {
              const eventToDisplay = {
                event_id: event.event_id,
                title: event.title,
                start: new Date(event.start),
                end: new Date(event.end)
              }
              if (event.color !== null) {
                eventToDisplay.color = event.color
              }
              return eventToDisplay
            })
            res(events)
          });
        })
      }
  
    render() {
    return (
      <div><ClubScheduler 
      remoteEventsPromise = { this.fetchEvents }
      handleOnDelete={ (params) => {} }
    /></div>
    )
  }
}
