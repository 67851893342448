import API from './api'
import tokenService from './tokenService'

// TODO mettre dans une variable d'environement
const API_URL = `${process.env.REACT_APP_SERVICE_API_URL}/auth/`

class AuthService {
  // TODO Async
  login(username, password) {
    return API.post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          tokenService.setUser(response.data)
        }

        return response.data;
      });
  }

  logout() {
    tokenService.removeUser()
  }

  register(username, email, password) {
    return API.post(API_URL + "signup", {
      username,
      email,
      password
    }).then(response => {
      if (response.data.accessToken) {
        tokenService.setUser(response.data)
      }
      return response.data;
    })
  }

  getCurrentUser() {
    return tokenService.getUser();
  }

  isLogin() {
    const user = this.getCurrentUser()
    if (user !== null) {
      return true
    }
    return false
  }

  isClubAdmin(clubId) {
    const user = this.getCurrentUser()
    if (user !== null) {
      const clubAuthorities = user.clubAuthorities
      const clubAuthority = clubAuthorities.find(a => a.clubId === parseInt(clubId))
      if (clubAuthority !== undefined && clubAuthority !== null && clubAuthority.userRole === 'ADMIN') {
        return true
      }
    }
    return false
  }

  isClubMember(clubId) {
    const user = this.getCurrentUser()
    if (user !== null) {
      const clubAuthorities = user.clubAuthorities
      const clubAuthority = clubAuthorities.find(a => parseInt(a.clubId) === parseInt(clubId))
      if (clubAuthority !== undefined && clubAuthority !== null) {
        return true
      }
    }
    return false
  }

  getClubMember(clubId) {
    const user = this.getCurrentUser()
    if (user !== null) {
      const clubAuthorities = user.clubAuthorities
      const clubAuthority = clubAuthorities.find(a => parseInt(a.clubId) === parseInt(clubId))
      if (clubAuthority !== undefined && clubAuthority !== null) {
        return clubAuthority
      }
    }
    return null
  }

  isMemberThisClub(clubId, id) {
    const user = this.getCurrentUser()
    if (user !== null) {
      const clubAuthorities = user.clubAuthorities
      const clubAuthority = clubAuthorities.find(a => a.clubId === parseInt(clubId) && a.memberId === parseInt(id))
      console.log('GIGIR ', clubAuthority)
      if (clubAuthority !== undefined && clubAuthority !== null  && clubAuthority.userRole !== null && clubAuthority.userRole !== 'WAITING') {
        return true
      }
    }
    return false
  }

  addMemberInClubAuthorities(clubId, id) {
    const user = this.getCurrentUser()
    const clubAuthorities = user.clubAuthorities
    clubAuthorities.push({
      clubId: clubId,
      memberId: id,
      userRole: 'WAITING'
    })
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new AuthService();