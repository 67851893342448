import React, { Component } from 'react'
import ClubTabs from '../components/ClubTabs';
import ClubService from '../services/clubService';
import { Typography } from '@mui/material';

class Club extends Component {
  constructor(props) {
    super(props)
    this.state = {
      club: {}
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    ClubService.get(clubId).then((response) => {
      this.setState({ club: response.data })
    })
  }

  render() {
    return (
      <div>
        <Typography variant="h3">
        { this.state.club.name }
        </Typography>
        <Typography variant="subtitle1">
        { this.state.club.description }
        </Typography>
        <ClubTabs {...this.props}></ClubTabs>
      </div>
    )
  }
}

export default Club