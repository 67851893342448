import API from './api'

const API_URL = `${process.env.REACT_APP_SERVICE_API_URL}/clubs/`

class AdminService {
  getAllPointCalculationMethods(clubId) {
    const apiUrl = API_URL + clubId + '/pointCalculationMethods/'
    return API.get(apiUrl);
  }
  getPointCalculationMethod(clubId, id) {
    const apiUrl = API_URL + clubId + '/pointCalculationMethods/' + id
    return API.get(apiUrl);
  }
  createPointCalculationMethod(clubId, data) {
    const apiUrl = API_URL + clubId + '/pointCalculationMethods/'
    return API.post(apiUrl, data)
  }
  updatePointCalculationMethod(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/pointCalculationMethods/' + id
    return API.put(apiUrl, data)
  }
  updateUserRole(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/members/' + id + '/updateRole'
    return API.put(apiUrl, data)
  }
  validateUserId(clubId, id) {
    const apiUrl = API_URL + clubId + '/members/' + id + '/validateUserId'
    return API.put(apiUrl, {})
  }
  getAllTournamentStructures(clubId) {
    const apiUrl = API_URL + clubId + '/tournamentStructures/'
    return API.get(apiUrl);
  }
  getTournamentStructure(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournamentStructures/' + id
    return API.get(apiUrl);
  }
  createTournamentStructure(clubId, data) {
    const apiUrl = API_URL + clubId + '/tournamentStructures/'
    return API.post(apiUrl, data)
  }
  updateTournamentStructure(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/tournamentStructures/' + id
    return API.put(apiUrl, data)
  }
}

export default new AdminService();