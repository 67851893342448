import React, { Fragment } from 'react';
import { Icon } from '@iconify/react';
import trophyIcon from '@iconify-icons/mdi/trophy';
import medalIcon from '@iconify-icons/mdi/medal';
import findMedalColorByRank from '../utils/findMedalColorByRank'

const Top3 = (props) => {
  const icon = props.type === 'ranking'? trophyIcon : medalIcon
  return (
    <Fragment>
      { props.top3.map(element => {
    const medalColor = findMedalColorByRank(element.rank)
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }}>
        <Icon icon={ icon } style={{ color: medalColor, fontSize: '24px'}}/>
        <span style={{ 
          marginLeft: '10px'
        }}>{ element.pseudo }</span>
    </div>
    )
  }) }
    </Fragment>
  );
}

export default Top3