import API from './api'

const API_URL = `${process.env.REACT_APP_SERVICE_API_URL}/clubs/`

class EventService {
  getAll(clubId) {
    const apiUrl = API_URL + clubId + '/events/'
    return API.get(apiUrl);
  }

  get(clubId, id) {
    const apiUrl = API_URL + clubId + '/events/' + id
    return API.get(apiUrl);
  }

  create(clubId, data) {
    const apiUrl = API_URL + clubId + '/events/'
    return API.post(apiUrl, data)
  }

  update(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/events/' + id
    return API.put(apiUrl, data)
  }

  delete(clubId, id) {
    const apiUrl = API_URL + clubId + '/events/' + id
    return API.delete(apiUrl)
  }
}

export default new EventService();