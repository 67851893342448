import React from 'react';
import ClubDetail from '../pages/ClubDetail';
import ClubMembers from '../pages/ClubMembers';
import ClubRankings from '../pages/ClubRankings';
import ClubTournaments from '../pages/ClubTournaments';
import Member from '../pages/Member';
import EditMember from '../pages/EditMember';
import EditRanking from '../pages/EditRanking';
import EditPointCalculationMethods from '../pages/EditPointCalculationMethods';
import EditTournament from '../pages/EditTournament';
import EditTournamentMembers from '../pages/EditTournamentMembers';
import DivideTournamentMembers from '../pages/DivideTournamentMembers';
import TournamentTimer from '../pages/TournamentTimer';
import Ranking from '../pages/Ranking';
import Tournament from '../pages/Tournament';
import { AppBar, Tabs, Tab } from '@mui/material/'
import { Switch, Route, Link } from "react-router-dom";
import ClubAdmin from '../pages/ClubAdmin';

import AuthService from '../services/authService'
import EditTournamentStructure from '../pages/EditTournamentStructure';
import EditEvent from '../pages/EditEvent';

export default function ClubTabs(props) {
  const clubId = props.match.params.clubId
  const pathname = props.location.pathname
  let initialValue = '/'
  if (pathname.includes('members')) {
    initialValue = '/members'
  } else if (pathname.includes('rankings')) {
    initialValue = '/rankings'
  } else if (pathname.includes('tournaments')) {
    initialValue = '/tournaments'
  }  else if (pathname.includes('admin')) {
    initialValue = '/admin'
  }
  const [value, setValue] = React.useState(initialValue);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }
  const isAdmin = AuthService.isClubAdmin(clubId)
  const adminTab = (isAdmin)? (<Tab label="Administration" value="/admin" component={Link} to={ `/clubs/${clubId}/admin` }/>) : null
  return (
    <div>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" textColor="inherit">
          <Tab label="Accueil" value="/" component={Link} to={ `/clubs/${clubId}` } />
          <Tab label="Membres" value="/members" component={Link} to={ `/clubs/${clubId}/members` }/>
          <Tab label="Classements" value="/rankings" component={Link} to={ `/clubs/${clubId}/rankings` }/>
          <Tab label="Tournois" value="/tournaments" component={Link} to={ `/clubs/${clubId}/tournaments` }/>
          { adminTab }
        </Tabs>
      </AppBar>
      <Switch>
        <Route exact path="/clubs/:clubId" component={ ClubDetail }/>
        <Route exact path="/clubs/:clubId/members" component={ ClubMembers }/>
        <Route exact path="/clubs/:clubId/members/edit" component={ EditMember }/>
        <Route exact path="/clubs/:clubId/members/:id" component={ Member }/>
        <Route exact path="/clubs/:clubId/rankings" component={ ClubRankings }/>
        <Route exact path="/clubs/:clubId/rankings/edit" component={ EditRanking }/>
        <Route exact path="/clubs/:clubId/rankings/:id" component={ Ranking }/>
        <Route exact path="/clubs/:clubId/tournaments" component={ ClubTournaments }/>
        <Route exact path="/clubs/:clubId/tournaments/edit" component={ EditTournament }/>
        <Route exact path="/clubs/:clubId/tournaments/:id/editMembers" component={ EditTournamentMembers }/>
        <Route exact path="/clubs/:clubId/tournaments/:id/divideMembers" component={ DivideTournamentMembers }/>
        <Route exact path="/clubs/:clubId/tournaments/:id/tournamentTimer" component={ TournamentTimer }/>
        <Route exact path="/clubs/:clubId/tournaments/:id" component={ Tournament }/>
        <Route exact path="/clubs/:clubId/admin" component={ ClubAdmin }/>
        <Route exact path="/clubs/:clubId/admin/pointCalculationMethods/:id" component={ EditPointCalculationMethods }/>
        <Route exact path="/clubs/:clubId/admin/tournamentStructure/:id" component={ EditTournamentStructure }/>
        <Route exact path="/clubs/:clubId/events/edit" component={ EditEvent }/>
      </Switch>
    </div>
  );
}
