import React from 'react'

import { Typography } from '@mui/material';

export default function PokerTimer(props) {

  const structure = props.structure.details
  const pause = props.pause
  const currentRoundId = props.currentRoundId;
  const roundTimeLeft = props.roundTimeLeft;

  const renderTimer = () => {
    const minutes =  Math.floor((roundTimeLeft / 60) % 60)
    let seconds =  Math.floor((roundTimeLeft) % 60)
    const renderPauseText = pause ? (<>(Pause)</>): null
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    return <div>
      <Typography variant="h2" align="center">
      { minutes }:{ seconds } {renderPauseText}
      </Typography>
      
    </div>
  }

  const renderRoundInfos = () => {
    if (structure.length === 0) {
      return null
    }
    const currentRoundStructure = structure[currentRoundId]

    if (currentRoundStructure.type === 'ROUND') {
      const renderAnte = currentRoundStructure?.ante !== null && currentRoundStructure?.ante > 0 ? (
        <div>
          Ante : { currentRoundStructure.ante }
        </div>
      ) : null
      
      return <div>
        <Typography variant="h4" align="center">
         Round { currentRoundId + 1 }
        </Typography>
  
        <Typography variant="h4"align="center">
         Binds { currentRoundStructure.smallBlind } / { currentRoundStructure.bigBlind }
         { renderAnte }
        </Typography>
        
      </div>
    } else {
      return <div>
        <Typography variant="h4" align="center">
          Pause
        </Typography>
      </div>
    }
  }

  return (
    <div>
        { renderTimer() }
        { renderRoundInfos() }
      </div>
  )
}
