import API from './api'

const API_URL = `${process.env.REACT_APP_SERVICE_API_URL}/clubs/`


class ClubService {
  getAll() {
    return API.get(API_URL);
  }

  get(id) {
    return API.get(API_URL + id);
  }

  getEvents(id, data) {
    console.log(JSON.stringify(data))
    return API.get(API_URL + id + '/eventsForScheduler', { params : data});
  }

  create(data) {
    return API.post(API_URL, data)
  }
  
  getUserBoard() {
    return API.get(API_URL + 'user');
  }

  getModeratorBoard() {
    return API.get(API_URL + 'mod');
  }

  getAdminBoard() {
    return API.get(API_URL + 'admin');
  }
}

export default new ClubService();