import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Form from 'react-validation/build/form';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputText from '../components/InputText'
import InputPassword from '../components/InputPassword'

import AuthService from '../services/authService';

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.state = {
      username: '',
      password: '',
      loading: false,
      message: '',
      logoutMessage: (props.location.state && props.location.state.logoutMessage) || '',
      redirect: (props.location.state && props.location.state.redirect) || ''
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: '',
      loading: true
    });

    // TODO appel du service de login en mode async
    AuthService.login(this.state.username, this.state.password).then(
      () => {
        if (this.state.redirect === '') {
          this.props.history.push('/profile');
          window.location.reload();
        } else {
          this.props.history.push(this.state.redirect);
          window.location.reload();
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
    
  }

  render() {
    const logoutError = this.state.logoutMessage === '' ? '' : (<Typography component="h2" variant="h6" style={{ color: "red" }}>
    { this.state.logoutMessage }
  </Typography>)
    return (
      <div>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        { logoutError }
        <Form onSubmit={this.handleLogin} >
            <InputText
            required={ true }
            id="username"
            label="User name"
            name="User name"
            autoComplete="user name"
            autoFocus={ true }
            value={ this.state.username }
            onInputChange={ this.onChangeUsername }
          />
          <InputPassword
            id="password"
            label="Password"
            onInputChange={this.onChangePassword}
            value={ this.state.password }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/resetPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          {this.state.message && (
              <div className='form-group'>
                <div className='alert alert-danger' role='alert'>
                  {this.state.message}
                </div>
              </div>
            )}
          </Form>
      </div>
    )
  }
}
