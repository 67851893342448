import React, { Component, Fragment } from 'react'
import RankingService from '../services/rankingService'
import { Link } from "react-router-dom"
import { Button, TableCell, Typography } from '@mui/material'
import InputSelect from '../components/InputSelect'
import AdminBlockquote from '../components/AdminBlockquote'
import TableWithPagination from '../components/TableWithPagination'
import Top3 from '../components/Top3';

import AuthService from '../services/authService';

const rankingStates = [
  { value: 'ALL', text: 'Tous' },
  { value: 'A', text: 'A venir' },
  { value: 'E', text: 'En cours' },
  { value: 'T', text: 'Terminé' }
]

const columns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'beginDate', headerName: 'Begin date', width: 130 },
  { field: 'endDate', headerName: 'End date', width: 130 },
  { field: 'tournamentCount', headerName: 'Tournois', width: 130 },
  { field: 'top3', headerName: 'Top 3', width: 150 }
]

export default class ClubRankings extends Component {
  constructor(props) {
    super(props)
    this.onChangeFilterByState = this.onChangeFilterByState.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this)
    this.state = {
      rankings: [],
      filterByState: "ALL"
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    RankingService.getAll(clubId).then((response) => {
      this.setState({ rankings: response.data })
      this.filterRankings(this.state.filterByState)
    })
  }

  onChangeFilterByState(e) {
    this.setState({
      filterByState: e.target.value
    })
    this.filterRankings(e.target.value)
  }

  filterRankings(filterByState) {
    const { rankings } = this.state
    if (rankings.length > 0) {
      for (const ranking of rankings) {
        ranking.hide = false
        if (filterByState !== "ALL") {
          if (filterByState !==  ranking.state) {
                ranking.hide = true
          }
        }
      }
    }
    this.setState({ rankings })
  }

  handleRowClick(event, id) {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/rankings/' + id )
    window.location.reload()
  }

  render() {
    const clubId = this.props.match.params.clubId
    const isAdmin = AuthService.isClubAdmin(clubId)
    const addRankingLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/rankings/edit`,
    }}>
      Nouveau Classement
    </Button>) : null
    const { rankings } = this.state
    return (
      <div>
        <blockquote>
          <Typography variant="h6">
            Filtres
          </Typography>
          <InputSelect 
            id="filterByState"
            label="Statut"
            required= { true }
            value={ this.state.filterByState || "ALL" }
            availableValues={ rankingStates }
            onInputChange={ this.onChangeFilterByState }
          />
        </blockquote>
        <AdminBlockquote clubId={ clubId } adminElements={(<Fragment>{ addRankingLink }</Fragment>)} />
        <TableWithPagination
          columns={ columns }
          rows= { rankings.filter(ranking => ranking.hide === false) }
          onRowClick={ this.handleRowClick }
          cursorPointerOnHover={ true }
          rowDisplayMethod={(row) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_name`}>
                  { row.name }
                </TableCell>
                <TableCell key={`${row.id}_begin_date`}>
                  {  new Intl.DateTimeFormat('fr-FR').format(new Date(row.beginDate )) }
                </TableCell>
                <TableCell key={`${row.id}_end_date`}>
                  {  new Intl.DateTimeFormat('fr-FR').format(new Date(row.endDate )) }
                </TableCell>
                <TableCell key={`${row.id}_tournament_count`}>
                  { row.tournamentCount }
                </TableCell>
                <TableCell key={`${row.id}_top3`}>
                  <Top3 top3={ row.top3 } type='ranking' />
                </TableCell>
              </Fragment>
            )
          }}
        />
      </div>
    )
  }
}
