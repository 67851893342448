import React, { Component } from 'react'
import { Typography } from '@mui/material'
import { TwitterPicker } from 'react-color'


export default class ColorPicker extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onInputChange(e)
  }

  render() {
    const { color } = this.props
    return (
      <div style={{ marginBottom: '10px', marginTop: '10px' }}>
        <Typography>Couleur</Typography>
        <TwitterPicker
          color={ color }
          onChangeComplete={ this.handleChange }
        />
      </div>
    )
  }
}
