import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"
import AuthService from '../services/authService'
import TournamentService from '../services/tournamentService'

import Chip from '@mui/material/Chip';

import TableCell from '@mui/material/TableCell';

import { Icon } from '@iconify/react';
import medalIcon from '@iconify-icons/mdi/medal';
import bubbleIcon from '@iconify/icons-ic/outline-bubble-chart';
import trophyIcon from '@iconify-icons/mdi/trophy';
import { Button, Typography } from '@mui/material';
import AdminBlockquote from '../components/AdminBlockquote'
import TableWithPagination from '../components/TableWithPagination'
import findMedalColorByRank from '../utils/findMedalColorByRank'
import findBubbleColor from '../utils/findBubbleColor'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';

const membersColumns = [
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'pseudo', headerName: 'Pseudo', width: 130 },
  { field: 'killer', headerName: 'Eliminé par', whidth: 130 },
  { field: 'kill_count', headerName: 'Nombre de kills', whidth: 100 },
  { field: 'points', headerName: 'Points', width: 100}
]

class Tournament extends Component {
  constructor(props) {
    super(props)
    this.handleMemberRowClick = this.handleMemberRowClick.bind(this)
    this.handleRankingClick = this.handleRankingClick.bind(this)
    this.handleClickRegister = this.handleClickRegister.bind(this)
    this.handleClickUnregister = this.handleClickUnregister.bind(this)
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this)
    this.handleClickDelete = this.handleClickDelete.bind(this)
    this.handleConfirmDeleteTournament = this.handleConfirmDeleteTournament.bind(this)

    this.state = {
      tournament: {},
      members: [],
      rankings: [],
      structure: null,
      openDeleteDialog: false
    }
  }

  handleMemberRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/members/' + id )
    window.location.reload()
  }

  handleRankingClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/rankings/' + id )
    window.location.reload()
  }

  handleClickRegister(e) {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.register(clubId, id).then((response) => {
      window.location.reload()
    })
  }

  handleClickUnregister(e) {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.unregister(clubId, id).then((response) => {
      window.location.reload()
    })
  }

  handleCloseDeleteDialog(e) {
    this.setState({ openDeleteDialog: false })
  }

  handleClickDelete(e) {
    this.setState({ openDeleteDialog: true })
  }

  handleConfirmDeleteTournament(e) {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.delete(clubId, id).then((response) => {
      this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/')
      window.location.reload()
    })
    this.setState({ openDeleteDialog: false })
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.get(clubId, id).then((response) => {
      this.setState({ tournament: response.data.tournament })
      this.setState({ members: response.data.members })
      this.setState({ rankings: response.data.rankings })
      this.setState({ structure: response.data.tournamentStructure })
    })
  }

  showButtonRegister() {
    if (this.isRegistrationOpened() === false) {
      return false
    }

    const { clubId } = this.props.match.params
    const clubMember = AuthService.getClubMember(clubId)
    const members = this.state.members

    if (clubMember === null ) {
      return false
    }
    const existingMember = members.find(a => parseInt(a.id) === parseInt(clubMember.memberId))
    return (existingMember !== undefined && existingMember !== null) === false
  }

  showButtonUnregister() {
    const { clubId } = this.props.match.params
    const clubMember = AuthService.getClubMember(clubId)
    const members = this.state.members

    if (clubMember === null) {
      return false
    }

    const existingMember = members.find(a => parseInt(a.id) === parseInt(clubMember.memberId))
    return (existingMember !== undefined && existingMember !== null)
  }

  showButtonRegisterMembers() {
    if (this.isRegistrationOpened() === false) {
      return false
    }
    const { clubId } = this.props.match.params
    const isAdmin = AuthService.isClubAdmin(clubId)
    if (isAdmin) {
      return true
    }
    return false
  }

  showButtonStartTournament() {
    if (this.state.structure === null) {
      return false
    }
    const { clubId } = this.props.match.params
    const isAdmin = AuthService.isClubAdmin(clubId)
    if (isAdmin) {
      return true
    }
    return false
  }

  isRegistrationOpened() {
    const tournament = this.state.tournament
    
    if (tournament.date !== undefined && new Date(tournament.date) > new Date()) {
      return true
    }
    return false
  }

  render() {
    const { clubId, id } = this.props.match.params
    const isAdmin = AuthService.isClubAdmin(clubId)
    const bubbleElement = (<Icon icon={bubbleIcon} style={{ color: findBubbleColor(), fontSize: '24px'}}/>)
    const editTournamentLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/tournaments/edit`,
      state: {
        id    
      }
    }}>
      Editer le tournoi
    </Button>) : null
    const deleteTournament = (isAdmin)? (
      <>
      
    <Button variant="contained" onClick={ this.handleClickDelete } color="error">
      Supprimer
    </Button>
    <Dialog
    open={this.state.openDeleteDialog}
    onClose={this.handleCloseDeleteDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Suppression d'un tournoi"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Êtes-vous sur de vouloir supprimer le tournoi ?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={ this.handleCloseDeleteDialog } color="primary">
        Non
      </Button>
      <Button onClick={ this.handleConfirmDeleteTournament } color="primary" autoFocus>
        Oui
      </Button>
    </DialogActions>
  </Dialog>
  </>) : null
    const divideMembersLink = (isAdmin)? (<Button variant="contained" href={`/clubs/${ clubId }/tournaments/${ id }/divideMembers` }>
      Répartir les joueurs en plusieurs SNG
    </Button>) : null
    const buttonRegister = this.showButtonRegister() ? <Button variant="contained" onClick={ this.handleClickRegister }>S'inscrire</Button>
     : null
    const buttonUnregister = this.showButtonUnregister() ? <Button variant="contained" onClick={ this.handleClickUnregister }>Se désinscrire</Button>
    : null
    const buttonRegisterMembers = this.showButtonRegisterMembers() ? <Button variant="contained" href={`/clubs/${ clubId }/tournaments/${ id }/editMembers`} color="primary">Ajouter des membres</Button>
    : null
    const buttonStartTournament = this.showButtonStartTournament() ? <Button variant="contained" href={`/clubs/${ clubId }/tournaments/${ id }/tournamentTimer`} color="primary">Démarrer le tournoi</Button>
    : null
    const { tournament, members, rankings }= this.state
    const dateTimeFormatOptions = {
      year: "numeric", month: "numeric", day: "numeric",
      hour: "numeric", minute: "numeric",
      hour12: false
    }
    const formattedDate = (tournament.date !== null && tournament.date !== undefined) ? new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(tournament.date)): null    
    return (
      <div>
        <Typography variant="h6">
        Date : { formattedDate }
        </Typography>
        <Typography variant="h6">
        Classement(s) : 
        { rankings.map((ranking) => (
          <Chip
          key={ ranking.id }
          icon={<Icon icon={ trophyIcon } />}
          style={{ marginLeft: '10px' }}
          label={ranking.name}
          clickable
          onClick={event => this.handleRankingClick(event, ranking.id)}
          color="primary"
        />
        ))}
        </Typography>
        <AdminBlockquote clubId={ clubId } adminElements={
          (<Fragment>
            <Stack direction="row" spacing={1}>
              { editTournamentLink }
              { buttonRegisterMembers }
              { divideMembersLink }
              { buttonStartTournament }
              { deleteTournament }
            </Stack>
          </Fragment>) } >
        </AdminBlockquote>
        
        { buttonRegister }
        { buttonUnregister }
        <TableWithPagination
          columns={ membersColumns }
          rows= { members }
          onRowClick={ this.handleMemberRowClick }
          cursorPointerOnHover={ true }
          rowDisplayMethod={(row) => {
            const medalColor = findMedalColorByRank(row.rank)
            const showBubbleIcon = row.is_bubble === 1 ? bubbleElement : null
            const rank = (row.rank !== null && row.rank <= 3)?(
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
                <span style={{ 
                  marginRight: '10px'
                }}>  { row.rank }</span>
                <Icon icon={medalIcon} style={{ color: medalColor, fontSize: '24px'}}/>
                { showBubbleIcon }
              </div>
            ):(<div>{ row.rank } { showBubbleIcon }</div>)
            return (
              <Fragment>
                <TableCell key={`${row.id}_rank`}>
                  { rank }
                </TableCell>
                <TableCell key={`${row.id}_pseudo`}>
                  { row.pseudo }
                </TableCell>
                <TableCell key={`${row.id}_killer`}>
                  { row.killer }
                </TableCell>
                <TableCell key={`${row.id}_kill_count`}>
                  { row.killCount }
                </TableCell>
                <TableCell key={`${row.id}_points`}>
                  { row.points }
                </TableCell>
              </Fragment>
            )
          }}
        />
      </div>
    )
  }
}

export default Tournament