import React, { Component, Fragment } from 'react'

import TableCell from '@mui/material/TableCell'

import { Icon } from '@iconify/react'
import trophyIcon from '@iconify-icons/mdi/trophy'
import medalIcon from '@iconify-icons/mdi/medal'
import sharkIcon from '@iconify-icons/mdi/shark-fin'
import bubbleIcon from '@iconify/icons-ic/outline-bubble-chart'
import { Tab, Tabs, AppBar, Typography } from '@mui/material'

import RankingService from '../../services/rankingService'
import TabPanel from '../../components/TabPanel'

import TableWithPagination from '../../components/TableWithPagination'
import findMedalColorByRank from '../../utils/findMedalColorByRank'
import findBubbleColor from '../../utils/findBubbleColor'

const membersColumns = [
  { field: 'rank', headerName: 'Place', width: 70 },
  { field: 'games', headerName: 'Parties', width: 120 },
  { field: 'points', headerName: 'Points', width: 100}
]

const killerRankingColumns = [
  { field: 'rank', headerName: 'Place', width: 70 },
  { field: 'pseudo', headerName: 'Pseudo', width: 120 },
  { field: 'killCount', headerName: 'Nombre de kills', width: 100}
]

export default class Ranking extends Component {
  constructor(props) {
    super(props)

    this.handleRankingTabChange = this.handleRankingTabChange.bind(this)
    this.handleMemberRowClick = this.handleMemberRowClick.bind(this)

    this.state = {
      tabvalue: 0,
      ranking: { beginDate: new Date(), endDate: new Date() },
      members: [],
      killerRanking: [],
      sessionCount: 0
    }
  }

  handleRankingTabChange(event, newValue) {
    this.setState({ tabvalue: newValue})
  }

  handleMemberRowClick = (event, id) => {
    window.open('/clubs/' + this.props.match.params.clubId + '/members/' + id, '_blank');
    // this.props.history.push('/clubs/' + this.props.match.params.clubId + '/members/' + id )
    // window.location.reload()
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    RankingService.getDetails(clubId, id).then((response) => {
      this.setState({ ranking: response.data.ranking })
      this.setState({ members: response.data.members })
      this.setState({ killerRanking: response.data.killerRanking })
      this.setState({ sessionCount: response.data.sessionCount })
    })
  }
  
  render() {
    const { ranking, members, killerRanking, sessionCount }= this.state
    const goldColor = findMedalColorByRank(1)
    const silverColor = findMedalColorByRank(2)
    const bronzeColor = findMedalColorByRank(3)
    return (
      <div>
        <Typography variant="h4" align="center">
          { ranking.name }
        </Typography>
        <AppBar position="static" color="transparent">
        <Tabs value={this.state.tabvalue} onChange={this.handleRankingTabChange} aria-label="simple tabs example" variant="scrollable">
          <Tab label="Classement général" value={0}/>
          <Tab label="Classement killer" value={1}/>
        </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabvalue} index={0}>
          <TableWithPagination
            columns={ membersColumns }
            rows= { members }
            onRowClick={ this.handleMemberRowClick }
            cursorPointerOnHover={ true }
            hideRowPerPage={ true }
            rowDisplayMethod={(row) => {
              let medalColor = findMedalColorByRank(row.rank)  
              const rank = (row.rank <= 3)?(
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                  <Icon icon={trophyIcon} style={{ color: medalColor, fontSize: '24px', marginRight: '10px'}}/>
                  <span>{ row.rank } { row.pseudo }</span>
                  
                </div>
              ):(<div>{ row.rank } { row.pseudo }</div>)
              return (
                <Fragment>
                  <TableCell key={`${row.id}_rank`}>
                    { rank }
                  </TableCell>
                  <TableCell key={`${row.id}_games`}>
                      { row.participationCount } / { sessionCount }
                      <div style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}>  
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: goldColor, fontSize: '18px'}}/> 
                          { row.top3.top1 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: silverColor, fontSize: '18px'}}/>
                          { row.top3.top2 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: bronzeColor, fontSize: '18px'}}/>
                          { row.top3.top3 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={bubbleIcon} style={{ color: findBubbleColor(), fontSize: '18px'}}/>
                          { row.top3.bubble }
                        </div>
                      </div>
                    </TableCell>
                  <TableCell key={`${row.id}_points`}>
                    { row.points }
                  </TableCell>
                </Fragment> 
              )
            }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={1}>
          <TableWithPagination
            columns={ killerRankingColumns }
            rows= { killerRanking }
            onRowClick={ this.handleMemberRowClick }
            cursorPointerOnHover={ true }
            hideRowPerPage={ true }
            rowDisplayMethod={(row, index) => {
              const killerRank = row.rank
              let medalColor = findMedalColorByRank(killerRank)  
              const rank = (killerRank <= 3) ? (
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                  <span style={{ 
                    marginRight: '10px'
                  }}>  { killerRank }</span>
                  <Icon icon={ sharkIcon } style={{ color: medalColor, fontSize: '24px'}}/>
                </div>
              ):(<div>{ killerRank }</div>)
              return (
                <Fragment>
                  <TableCell key={`${row.id}_rank`}>
                      { rank }
                    </TableCell>
                    <TableCell key={`${row.id}_pseudo`}>
                      { row.pseudo }
                    </TableCell>
                    <TableCell key={`${row.id}_kill_count`}>
                      { row.killCount }
                    </TableCell>
                </Fragment>
              )
            }}
          />
        </TabPanel>
      </div>
    )
  }
}
