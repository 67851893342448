const findMedalColorByRank = (rank) => {
  switch (rank) {
    case 1:
      return 'gold'
    case 2:
      return'silver'
    case 3:
      return '#85530f'
    default:
      return
  }
}

export default findMedalColorByRank