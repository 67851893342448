import React, { Component } from 'react'
import Form from 'react-validation/build/form'
import Button from '@mui/material/Button'
import InputText from '../components/InputText'
import InputDate from '../components/InputDate'
import InputSelect from '../components/InputSelect'

import RankingService from '../services/rankingService'

const rankingStates = [
  { value: 'A', text: 'A venir' },
  { value: 'E', text: 'En cours' },
  { value: 'T', text: 'Terminé' }
]

export default class EditRanking extends Component {
  
  constructor(props) {
    super(props)
    this.handleEditRanking = this.handleEditRanking.bind(this)
    this.onChangeName = this.onChangeName.bind(this)
    this.onChangeBeginDate = this.onChangeBeginDate.bind(this)
    this.onChangeEndDate = this.onChangeEndDate.bind(this)
    this.onChangePointCalculationMethods = this.onChangePointCalculationMethods.bind(this)
    this.onChangeTournamentStructure = this.onChangeTournamentStructure.bind(this)
    this.onChangeState = this.onChangeState.bind(this)
    const isNewRanking = (this.props.location.state || {}).id === undefined
    this.state = {
      isNewRanking,
      ranking: {},
      availablePointCalculationMethods: [],
      availableTournamentStructure: [],
      loading: false,
      message: ''
    }
  }

  handleEditRanking(e) {
    e.preventDefault()
    const { ranking, isNewRanking } = this.state
    const clubId = this.props.match.params.clubId
    if (isNewRanking) {
      RankingService.create(clubId, ranking).then((response) => {
        this.props.history.push('/clubs/' + this.props.match.params.clubId + '/rankings/' + response.data.id )
        window.location.reload()
      })
      .catch((error) => {
        console.log('error', error)
      })
    } else {
      const id = this.props.location.state.id
      RankingService.update(clubId, id, ranking).then((response) => {
        this.props.history.push('/clubs/' + this.props.match.params.clubId + '/rankings/' + id )
        window.location.reload()
      })
      .catch((error) => {


if (error.response) {
  /*
   * The request was made and the server responded with a
   * status code that falls out of the range of 2xx
   */
  console.log(error.response.data);
  console.log(error.response.status);
  console.log(error.response.headers);
} else if (error.request) {
  /*
   * The request was made but no response was received, `error.request`
   * is an instance of XMLHttpRequest in the browser and an instance
   * of http.ClientRequest in Node.js
   */
  console.log(error.request);
} else {
  // Something happened in setting up the request and triggered an Error
  console.log('Error', error.message);
}
console.log(error);

        console.log('iiifidfififi')
        console.log('error', error)
        console.log('hhshsjkdshsq', error.response.data.message)
      })
    }
  }

  onChangeName(e) {
    const ranking = this.state.ranking
    ranking.name = e.target.value
    this.setState({ ranking })
  }

  onChangeBeginDate(e) {
    const ranking = this.state.ranking
    ranking.beginDate = e.target.value
    this.setState({ ranking })
  }

  onChangeEndDate(e) {
    const ranking = this.state.ranking
    ranking.endDate = e.target.value
    this.setState({ ranking })
  }

  onChangePointCalculationMethods(e) {
    const ranking = this.state.ranking
    ranking.pointCalculationMethodId = e.target.value
    this.setState({ ranking })
  }

  onChangeTournamentStructure(e) {
    const ranking = this.state.ranking
    ranking.tournamentStructureId = e.target.value
    this.setState({ ranking })
  }

  onChangeState(e) {
    const ranking = this.state.ranking
    ranking.state = e.target.value
    this.setState({ ranking })
  }

  componentDidMount() {
    // TODO Rechercher infos pour créer un classement
    // Liste des formules de création

    const clubId = this.props.match.params.clubId
    RankingService.prepare(clubId).then((response) => {
      // TODO
      this.setState({ availablePointCalculationMethods: response.data.pointCalculationMethods })
      this.setState({ availableTournamentStructure: response.data.tournamentStructures })
      if(!this.state.isNewRanking) {
        const id = this.props.location.state.id
        RankingService.get(clubId, id).then((response) => {
          const ranking = response.data
          this.setState({ ranking })
        })
      }
    })
    
  }

  render() {
    const { availablePointCalculationMethods, availableTournamentStructure } = this.state
    const pointCalculationMethodSelectList = availablePointCalculationMethods ? availablePointCalculationMethods.map(pointCalculationMethod => Object.assign({ value: pointCalculationMethod.id, text: pointCalculationMethod.name })) : []
    const tournamentStructureSelectList = availableTournamentStructure ? availableTournamentStructure.map(tournamentStructure => Object.assign({ value: tournamentStructure.id, text: tournamentStructure.name })) : []
    return (
      <div>
        <Form onSubmit={ this.handleEditRanking } >
          <InputText
            required={ true }
            id="name"
            label="Nom"
            name="Nom"
            autoComplete="Nom"
            autoFocus={ true }
            value={ this.state.ranking.name || "" }
            onInputChange={ this.onChangeName }
          />
          <InputDate
            required={ true }
            id="beginDate"
            label="Date de début"
            name="Date de début"
            autoComplete="beginDate"
            autoFocus={ true }
            value={ this.state.ranking.beginDate || "" }
            onInputChange={ this.onChangeBeginDate }
          />
          <InputDate
            required={ true }
            id="endDate"
            label="Date de fin"
            name="Date de fin"
            autoComplete="endDate"
            autoFocus={ true }
            value={ this.state.ranking.endDate }
            onInputChange={ this.onChangeEndDate }
          />
          <InputSelect 
            id="pointCalculationMethod"
            label="Méthode de calcul des points automatique"
            required= { false }
            value={ this.state.ranking.pointCalculationMethodId || "" }
            availableValues={ pointCalculationMethodSelectList }
            onInputChange={ this.onChangePointCalculationMethods }
          />
          <InputSelect 
            id="tournamentStructure"
            label="Structure des tournois"
            required= { false }
            value={ this.state.ranking.tournamentStructureId || "" }
            availableValues={ tournamentStructureSelectList }
            onInputChange={ this.onChangeTournamentStructure }
          />
          <InputSelect 
            id="state"
            label="Statut"
            required= { true }
            value={ this.state.ranking.state || "E" }
            availableValues={ rankingStates }
            onInputChange={ this.onChangeState }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
