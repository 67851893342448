import { Button } from '@mui/material';
import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import AdminBlockquote from '../components/AdminBlockquote';
import ClubScheduler from '../components/ClubScheduler';
import AuthService from '../services/authService';
import ClubService from '../services/clubService';

export default class ClubDetail extends Component {
  constructor(props) {
    super(props)

    this.fetchEvents = this.fetchEvents.bind(this);

    this.state = {
      club: {}
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    ClubService.get(clubId).then((response) => {
      this.setState({ club: response.data })
    })
  }

  async fetchEvents (query) {
    // TODO Une requête à chaque changement de mois, voir ce qu'il y a dans query
    console.log(query)
    const clubId = this.props.match.params.clubId
    return ClubService.getEvents(clubId, query).then((response) => {
      return new Promise((res) => {
        const events = response.data.map(event => {
          const eventToDisplay = {
            event_id: event.event_id,
            title: event.title,
            start: new Date(event.start),
            end: new Date(event.end)
          }
          if (event.color !== null) {
            eventToDisplay.color = event.color
          }
          return eventToDisplay
        })
        res(events)
      });
    })
    
  }

  render() {
    const { club } = this.state

    const isAdmin = AuthService.isClubAdmin(club.id)
    const handleOnDelete = (isAdmin) ? (params) => {} : (params) => {}
    const addMemberLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ club.id }/events/edit`
    }}>
      Nouvel évènement
    </Button>) : null
    
    return (
      <div>
        <AdminBlockquote clubId={ club.id } adminElements={(<Fragment>{ addMemberLink }</Fragment>)} />
        Description : { club.description }<br />
        Adresse : { club.adress }<br />
        { `${club.postCode} ${club.city}`}
        <ClubScheduler 
          remoteEventsPromise = { this.fetchEvents }
          handleOnDelete={ handleOnDelete }
        />
        
      </div>
    )
  }
}
