import React, { Component } from 'react'
import MemberService from '../services/memberService';
import Form from 'react-validation/build/form';
import Button from '@mui/material/Button';
import InputText from '../components/InputText';
import InputSelect from '../components/InputSelect'

const memberStates = [
  { value: 'A', text: 'Actif' },
  { value: 'I', text: 'Inactif' }
]

export default class EditMember extends Component {
  constructor(props) {
    super(props)
    this.handleEditMember = this.handleEditMember.bind(this);
    this.onChangePseudo = this.onChangePseudo.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeAdress = this.onChangeAdress.bind(this);
    this.onChangePostCode = this.onChangePostCode.bind(this);
    this.onChangeCity = this.onChangeCity.bind(this);
    this.onChangeBirthdate = this.onChangeBirthdate.bind(this);
    this.onChangeState = this.onChangeState.bind(this)
    const isNewMember = (this.props.location.state || {}).id === undefined
    this.state = {
      isNewMember,
      member: {
        state: 'A'
      },
      loading: false,
      message: ''
    }
  }

  handleEditMember(e) {
    e.preventDefault()
    this.setState({
      message: '',
      loading: true
    });
    const clubId = this.props.match.params.clubId
    if(this.state.isNewMember) {
      MemberService.create(clubId, this.state.member).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/members/${ result.data.id }`);
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    } else {
      const id = this.props.location.state.id
      MemberService.update(clubId, id, this.state.member).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/members/${ result.data.id }`);
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    }
  }

  onChangePseudo(e) {
    const member = {...this.state.member}
    
    member.pseudo = e.target.value
    this.setState({ member })
  }

  onChangeName(e) {
    const member = this.state.member
    member.name = e.target.value
    this.setState({ member })
  }

  onChangeFirstName(e) {
    const member = {...this.state.member}
    member.firstName = e.target.value
    this.setState({ member })
  }

  onChangeAdress(e) {
    const member = {...this.state.member}
    member.adress = e.target.value
    this.setState({ member })
  }

  onChangePostCode(e) {
    const member = {...this.state.member}
    member.postCode = e.target.value
    this.setState({ member })
  }

  onChangeCity(e) {
    const member = {...this.state.member}
    member.city = e.target.value
    this.setState({ member })
  }

  onChangeBirthdate(e) {
    const member = {...this.state.member}
    member.birthdate = e.target.value
    this.setState({ member })
  }

  onChangeState(e) {
    const member = {...this.state.member}
    member.state = e.target.value
    this.setState({ member })
  }

  componentDidMount() {
    if(!this.state.isNewMember) {
      const clubId = this.props.match.params.clubId
      const id = this.props.location.state.id
      MemberService.get(clubId, id).then((response) => {
        this.setState({ member: Object.assign({ state: response.data.member_member_club[0].state } , response.data) })
      })
    }
  }

  render() {
    return (
      <div>
        Edition d'un membre nouveau ou existant { this.state.isNewMember.toString() }<br />
        <Form onSubmit={ this.handleEditMember } >
        <InputText
            required={ true }
            id="pseudo"
            label="Pseudo"
            name="Pseudo"
            autoComplete="Pseudo"
            autoFocus={ true }
            value={ this.state.member.pseudo || "" }
            onInputChange={ this.onChangePseudo }
          />
          <InputText
            required={ false }
            id="name"
            label="Name"
            name="Name"
            autoComplete="Name"
            autoFocus={ false }
            value={ this.state.member.name || "" }
            onInputChange={ this.onChangeName }
          />
          <InputText
            required={ false }
            id="firstName"
            label="First name"
            name="First name"
            autoComplete="First name"
            autoFocus={ false }
            value={ this.state.member.firstName || "" }
            onInputChange={ this.onChangeFirstName }
          />
          <InputText
            required={ false }
            id="adress"
            label="Adress"
            name="Adress"
            autoComplete="Adress"
            autoFocus={ false }
            value={ this.state.member.adress || "" }
            onInputChange={ this.onChangeAdress }
          />
          <InputText
            required={ false }
            id="postCode"
            label="Post Code"
            name="Post Code"
            autoComplete="Post Code"
            autoFocus={ false }
            value={ this.state.member.postCode || "" }
            onInputChange={ this.onChangePostCode }
          />
          <InputText
            required={ false }
            id="city"
            label="City"
            name="City"
            autoComplete="City"
            autoFocus={ false }
            value={ this.state.member.city || "" }
            onInputChange={ this.onChangeCity }
          />
          <InputText
            required={ false }
            id="birthdate"
            label="Birthdate"
            name="Birthdate"
            autoComplete="Birthdate"
            autoFocus={ false }
            value={ this.state.member.birthdate || "" }
            onInputChange={ this.onChangeBirthdate }
          />
          <InputSelect 
            id="state"
            label="Statut"
            required= { true }
            value={ this.state.member.state || "A" }
            availableValues={ memberStates }
            onInputChange={ this.onChangeState }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
