import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import red from '@mui/material/colors/red';
import Container from '@mui/material/Container';
import NavBar from './components/NavBar';
import Home from './pages/Home'
import ForgottenPassword from './pages/ForgottenPassword'
import Login from './pages/Login'
import Profile from './pages/Profile'
import Account from './pages/Account'
import Register from './pages/Register'
import Club from './pages/Club'
import RankingWidget from './pages/widgets/RankingWidget';
import SchedulerWidget from './pages/widgets/SchedulerWidget';

import { createTheme , ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery';


const App = (props) => {

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = createTheme ({
    palette: {
      primary: {
        light: '#35afea',
        main: '#039be5',
        dark: '#026ca0',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      }
    },
  });

  const darkTheme = createTheme ({
    palette: {
      type: "dark",
      primary: red,
      secondary: {
        light: '#ffc1e3',
        main: '#f48fb1',
        dark: '#bf5f82'
      }
    }
  });

  const navbar = window.location.pathname.includes('widget') ? null : (<NavBar />) 

  return (
    <ThemeProvider theme={ prefersDarkMode ? darkTheme : theme }>
      <CssBaseline />
      <Router>
        <Switch>
          <Route exact path="/widget/clubs/:clubId/rankings/:id" component={ RankingWidget }/>
          <Route exact path="/widget/clubs/:clubId/scheduler" component={ SchedulerWidget }/>
        </Switch>
      </Router>
      { navbar }
      <Container maxWidth="md">
        <Router>
          <Switch>
            <Route exact path={["/", "/home"]} component={ Home } />
            <Route exact path="/login" component={ Login } />
            <Route exact path="/register" component={ Register } />
            <Route exact path="/resetPassword" component={ ForgottenPassword } />
            <Route exact path="/profile" component={ Profile } />
            <Route exact path="/account" component={ Account } />
            <Route path="/clubs/:clubId" component={ Club }/>
          </Switch>
        </Router>
      </Container>
    </ThemeProvider>
  )
}

export default App;
