import { TableCell } from '@mui/material'
import React from 'react'
import { Fragment } from 'react'
import TableWithPagination from './TableWithPagination'

const structureColumns = [
    { field: 'Type', headerName: 'Type', width: 80 },
    { field: 'Durée', headerName: 'Durée', width: 130 },
    { field: 'Détails', headerName: 'Blinds', width: 130 }
  ]

export default function TournamentStructureArray(props) {
  return (
    <TableWithPagination
          columns={ structureColumns }
          rows= { props.structure.details.map((detail, index) => { return Object.assign({ backgroundColor: (index === props.currentRoundId && props.started === true ? 'silver' : '')}, detail) } ) }
          rowDisplayMethod={(row, index) => {
            return (
              <Fragment>
                <TableCell key={`${index}_type`}>
                  { row.type === "PAUSE" ? "Pause": "Round" }
                </TableCell>
                <TableCell key={`${index}_minutes`}>
                  {  row.minutes }:00
                </TableCell>
                <TableCell key={`${row.id}_details`}>
                  { row.type === "ROUND" ? `Blinds ${row.smallBlind} / ${row.bigBlind} ${row.ante !== null && row.ante > 0 ? `  Ante ${ row.ante }` : ` ` }` : null}
                </TableCell>
              </Fragment>
            )
          }}
        />
  )
}
