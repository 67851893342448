import React, { Component } from 'react'
import Form from 'react-validation/build/form';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputSelect from '../components/InputSelect';

import TournamentService from '../services/tournamentService';

import { Icon } from '@iconify/react';
import deleteIcon from '@iconify-icons/mdi/delete';

const columns = [
    { field: 'member', headerName: 'Membre', width: 130 },
    { field: 'actions', headerName: 'Actions', width: 300 }
  ]

class EditTournamentMembers extends Component {
  constructor(props) {
    super(props)
    this.onChangeMemberInfos = this.onChangeMemberInfos.bind(this);
    this.handleClickAddMember = this.handleClickAddMember.bind(this)
    this.handleEditTournament = this.handleEditTournament.bind(this)
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this)
    this.state = {
      date: null,
      availableMembers: [],
      members: [],
      loading: false,
      message: ''
    }
  }

  handleClickAddMember(e) {
    const members = this.state.members
    members.push({ rank: members.length + 1 })
    this.setState({ members })
  }

  onChangeMemberInfos(e, index, field) {
    const members = this.state.members
    members[index][field] = e.target.value
    this.setState({ members })
  }

  handleClickDeleteMember(e, index) {
    const members = this.state.members
    members.splice(index, 1);
    this.setState({ members: members.map((member, index) => {
      return Object.assign(member, { rank: index + 1 })
    })})
  }

  handleEditTournament(e) {
    e.preventDefault()
    const { members } = this.state
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.registerMembers(clubId, id, { members }).then((response) => {
      this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id )
      window.location.reload()
    })
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    TournamentService.prepare(clubId).then((response) => {
      this.setState({ availableMembers: response.data.members })
      const clubId = this.props.match.params.clubId
      const id = this.props.match.params.id
      TournamentService.get(clubId, id).then((response) => {
        this.setState({ date: new Date(response.data.tournament.date).toISOString().slice(0,16) })
        this.setState({ members: response.data.members })
      })
    })
  }

  findMembersList(currentMember) {
    return this.state.availableMembers
      .filter(member => this.state.members.find(m => m.id === member.id && m.id !== currentMember.id) === undefined)
      .map(member => Object.assign({ value: member.id, text: member.pseudo }))
  }

  render() {
    const { date, members } = this.state
    const dateTimeFormatOptions = {
      year: "numeric", month: "numeric", day: "numeric",
      hour: "numeric", minute: "numeric",
      hour12: false
    }
    const formattedDate = (date !== null && date !== undefined)? new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(date)): null    
    return (
      <div>
        Ajout des members au tournoi du : { formattedDate }
        <Form onSubmit={ this.handleEditTournament } >
          <Button variant="contained" onClick={ this.handleClickAddMember }>Ajouter un membre</Button>
          <Paper style={{ height: '80%', width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                { members.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`member_${index}`}>
                    <TableCell>
                      <InputSelect 
                        id={ `${index}_member` }
                        label="member"
                        required= { true }
                        value={ row.id || "" }
                        availableValues={ this.findMembersList(row) }
                        onInputChange={(e) => this.onChangeMemberInfos(e, index, "id") }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Supprimer"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickDeleteMember(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ deleteIcon } style={{ fontSize: '18px'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Enregistrer
          </Button>
        </Form>
      </div>
    )
  }
}
export default EditTournamentMembers