import React, { Component } from 'react'
import AdminService from '../services/adminService'
import Form from 'react-validation/build/form'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import InputCheckBox from '../components/InputCheckBox'
import InputNumber from '../components/InputNumber'
import InputSelect from '../components/InputSelect'
import InputText from '../components/InputText'

const pointCalculationMethodTypeSelectList = [
  { value: 'INDICE', text: 'Indice' },
  { value: 'MATRIX', text: 'Matrice' }
]

export default class EditPointCalculationMethods extends Component {

  constructor(props) {
    super(props)
    this.onChangePointCalculationMethodInfos = this.onChangePointCalculationMethodInfos.bind(this)
    this.onChangeKoMode = this.onChangeKoMode.bind(this)
    this.onChangeMatrixMaxMemberCount = this.onChangeMatrixMaxMemberCount.bind(this)
    this.onChangeMatrixMinMemberCount = this.onChangeMatrixMinMemberCount.bind(this)
    this.onClickPreviousMatrixPage = this.onClickPreviousMatrixPage.bind(this)
    this.onClickNextMatrixPage = this.onClickNextMatrixPage.bind(this)
    this.handleEditPointCalculationMethod = this.handleEditPointCalculationMethod.bind(this)
    this.state = {
      pointCalculationMethod: {},
      matrix: [],
      matrixMinMemberCount: 0,
      matrixMaxMemberCount: 0,
      matrixPage: 1
    }
  }

  onChangePointCalculationMethodInfos(e, field) {
    const pointCalculationMethod = this.state.pointCalculationMethod
    pointCalculationMethod[field] = e.target.value
    this.setState({ pointCalculationMethod })
  }

  onChangeKoMode(e) {
    const pointCalculationMethod = this.state.pointCalculationMethod
    pointCalculationMethod.isKoMode = e.target.checked
    this.setState({ pointCalculationMethod })
  }

  onChangeMatrixMinMemberCount(e) {
    const minMemberCount = e.target.value
    if (minMemberCount > this.state.matrixMaxMemberCount) {
      this.setState({ matrixMaxMemberCount: minMemberCount })  
    }
    this.setState({ matrixMinMemberCount: minMemberCount })
    this.setState({ matrixPage: 1 })
  }

  onChangeMatrixMaxMemberCount(e) {
    const maxMemberCount = parseInt(e.target.value)
    if (maxMemberCount < this.state.matrixMinMemberCount) {
      this.setState({ matrixMinMemberCount: maxMemberCount })
    }
    this.setState({ matrixMaxMemberCount: maxMemberCount })
    this.setState({ matrixPage: 1 })
  }

  onClickPreviousMatrixPage() {
    let matrixPage = this.state.matrixPage
    if (matrixPage > 1) {
      matrixPage--
      this.setState({ matrixPage })
    }
  }

  onClickNextMatrixPage() {
    const { matrixPage, matrixMinMemberCount, matrixMaxMemberCount } = this.state
    const interval = matrixMaxMemberCount - matrixMinMemberCount
    if (interval > 10) {
      const maxMatrixPageCount = Math.ceil(interval/10)
      if (matrixPage < maxMatrixPageCount) {
        this.setState({ matrixPage: matrixPage + 1 })
      }
    }
  }

  onChangeMatrixElementPoints(e, rank, memberCount) {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    const { matrix  } = this.state
    const points = parseInt(e.target.value)
    const matrixElement = matrix.find(m => m.playerCount === memberCount && m.rank === rank)
    if (matrixElement !== undefined) {
      matrixElement.points = points
    } else {
      matrix.push({
        pointCalculationMethodId: parseInt(id),
        clubId: parseInt(clubId),
        playerCount: memberCount,
        rank,
        points: points
      })
    }
    this.setState({ matrix })
  }

  handleEditPointCalculationMethod(e) {
    e.preventDefault()
    const clubId = this.props.match.params.clubId
    const id = parseInt(this.props.match.params.id)

    const data = Object.assign({ matrix: this.state.matrix }, this.state.pointCalculationMethod)

    if (id === 0) {
      AdminService.createPointCalculationMethod(clubId, data).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/admin`)
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      })
    } else {
      AdminService.updatePointCalculationMethod(clubId, id, data).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/admin`)
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    AdminService.getPointCalculationMethod(clubId, id).then((response) => {
      this.setState({ pointCalculationMethod: response.data.pointCalculationMethod })
      this.setState({ matrix: response.data.matrix })

      if (this.state.matrix.length > 0) {
        let minMemberCount = 999
        let maxMemberCount = 0
        this.state.matrix.forEach(elem => {
          if (elem.playerCount < minMemberCount) {
            minMemberCount = elem.playerCount
          }
          if (elem.playerCount > maxMemberCount) {
            maxMemberCount = elem.playerCount
          }
          this.setState({ matrixMinMemberCount: minMemberCount })
          this.setState({ matrixMaxMemberCount: maxMemberCount })
        })
      }
    })
  }

  findMinMaxElementPage() {
    const { matrixPage, matrixMinMemberCount, matrixMaxMemberCount } = this.state
    const interval = matrixMaxMemberCount - matrixMinMemberCount
    if (interval > 10) {
      let maxMatrixElement = (matrixPage * 10) -1 + parseInt(matrixMinMemberCount)
      let minMatrixElement = maxMatrixElement - 10
      if (maxMatrixElement > matrixMaxMemberCount) {
        maxMatrixElement = matrixMaxMemberCount
      }
      if (minMatrixElement < matrixMinMemberCount) {
        minMatrixElement = matrixMinMemberCount
      }
      return { minMatrixElement, maxMatrixElement }
    } else {
      return { minMatrixElement: matrixMinMemberCount, maxMatrixElement: matrixMaxMemberCount }
    }
  }

  renderMatrixPoints(rank, matrixMinMemberCount, matrixMaxMemberCount) {
    const { matrix } = this.state
    const cellRank = []
    for (let memberCount = matrixMinMemberCount - 1; memberCount <= matrixMaxMemberCount; memberCount++) {
      const matrixElement = matrix.find(m => m.playerCount === memberCount && m.rank === rank)
      if (memberCount >= matrixMinMemberCount) {
        let points
        if (matrixElement !== undefined && matrixElement !== null) {
          points = matrixElement.points
        }
        const inputNumberIfNeeded = (rank <= memberCount) ? (
          <InputNumber
            required={ false }
            id={`matrix_${rank}_${memberCount}`}
            type="number"
            label="Points"
            name="points"
            autoFocus={ false }
            value={ points || "" }
            isOnlyInteger={ true }
            isOnlyPositive={ true }
            onInputChange={(e) => this.onChangeMatrixElementPoints(e, rank, memberCount) }
          />
        ) : null
        cellRank.push(<TableCell>
          { inputNumberIfNeeded }
        </TableCell>)
      }
    }
    return cellRank
  }

  render() {
    const { pointCalculationMethod, matrixMinMemberCount, matrixMaxMemberCount } = this.state
    const { minMatrixElement, maxMatrixElement } = this.findMinMaxElementPage()
    const headerMemberCount = []
    const rowsMemberCount = []

    for (let memberCount = minMatrixElement; memberCount <= maxMatrixElement; memberCount++) {
      headerMemberCount.push(<TableCell>{ memberCount }</TableCell>)
    }

    for (let rank = 1; rank <= maxMatrixElement; rank++) {
      rowsMemberCount.push(<TableRow>
        <TableCell variant="head">{ rank }</TableCell>
        { this.renderMatrixPoints(rank, minMatrixElement, maxMatrixElement) }
      </TableRow>)
    }

    return (
      <div>
        Edition d'une méthode de calcul des points
        <br />
        <Form onSubmit={ this.handleEditPointCalculationMethod } >
        <InputText
            required={ true }
            id="name"
            label="Nom"
            name="Nom"
            autoComplete="Nom"
            autoFocus={ true }
            value={ pointCalculationMethod.name || "" }
            onInputChange={ (e) => this.onChangePointCalculationMethodInfos(e, "name") }
          />
          <InputSelect 
            id="type"
            label="Type"
            required= { true }
            value={ pointCalculationMethod.type || "" }
            availableValues={ pointCalculationMethodTypeSelectList }
            onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "type") }
          />
          <InputCheckBox 
            name="isKoMode"
            label="Mode KO"
            checked={ pointCalculationMethod.isKoMode }
            onInputChange={(e) => this.onChangeKoMode(e) }
          />
          <InputNumber
            required={ false }
            id="bonusPerKill"
            type="number"
            label="Bonux par kill"
            name="bonusPerKill"
            autoFocus={ false }
            value={ pointCalculationMethod.bonusPerKill || "" }
            isOnlyInteger={ true }
            isOnlyPositive={ true }
            onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "bonusPerKill") }
          />
          <div id="indice-fields" style={{ display: pointCalculationMethod.type === 'INDICE' ? "block" : "none" }}>
            <InputNumber
              required={ false }
              id="buyIn"
              type="number"
              label="Buy In"
              name="buyIn"
              autoFocus={ false }
              value={ pointCalculationMethod.buyIn || "" }
              isOnlyInteger={ true }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "buyIn") }
            />
            <InputNumber
              required={ false }
              id="percentOfPaidPlayers"
              type="number"
              label="Pourcentage de joueurs payé"
              name="percentOfPaidPlayers"
              autoFocus={ false }
              value={ pointCalculationMethod.percentOfPaidPlayers || "" }
              isOnlyInteger={ true }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "percentOfPaidPlayers") }
            />
            <InputNumber
              required={ false }
              id="guaranteed"
              type="number"
              label="Garanti"
              name="guaranteed"
              autoFocus={ false }
              value={ pointCalculationMethod.guaranteed || "" }
              isOnlyInteger={ true }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "guaranteed") }
            />
            <InputNumber
              required={ false }
              id="addedFix"
              type="number"
              label="Ajouté fixe"
              name="addedFix"
              autoFocus={ false }
              value={ pointCalculationMethod.addedFix || "" }
              isOnlyInteger={ true }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "addedFix") }
            />
            <InputNumber
              required={ false }
              id="addedByPlayers"
              type="number"
              label="Ajouté par joueurs"
              name="addedByPlayers"
              autoFocus={ false }
              value={ pointCalculationMethod.addedByPlayers || "" }
              isOnlyInteger={ true }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "addedByPlayers") }
            />
            <InputNumber
              required={ false }
              id="indice"
              type="number"
              label="Indice"
              name="indice"
              autoFocus={ false }
              value={ pointCalculationMethod.indice || "" }
              isOnlyInteger={ false }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangePointCalculationMethodInfos(e, "indice") }
            />
          </div>

          <div id="matrix-fields" style={{ display: pointCalculationMethod.type === 'MATRIX' ? "block" : "none" }}>
            <InputNumber
              required={ false }
              id="matrixMinMemberCount"
              type="number"
              label="Nombre min de joueurs"
              name="matrixMinMemberCount"
              autoFocus={ false }
              value={ matrixMinMemberCount || "" }
              isOnlyInteger={ false }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangeMatrixMinMemberCount(e) }
            />
            <InputNumber
              required={ false }
              id="matrixMaxMemberCount"
              type="number"
              label="Nombre max de joueurs"
              name="matrixMaxMemberCount"
              autoFocus={ false }
              value={ matrixMaxMemberCount || "" }
              isOnlyInteger={ false }
              isOnlyPositive={ true }
              onInputChange={(e) => this.onChangeMatrixMaxMemberCount(e) }
            />

            <Button variant="contained" onClick={ this.onClickPreviousMatrixPage } style={{ marginRight: '10px' }}>Page précédente</Button>
            <Button variant="contained" onClick={ this.onClickNextMatrixPage }>Page suivante</Button>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre de joueurs<br />Classement</TableCell>
                  {
                    headerMemberCount
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rowsMemberCount
                }
              </TableBody>
            </Table>
          </div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
