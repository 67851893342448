import React, { Component, Fragment } from 'react'
import PokerTimer from '../components/PokerTimer'
import AdminBlockquote from '../components/AdminBlockquote'
import TabPanel from '../components/TabPanel'
import TableWithPagination from '../components/TableWithPagination'
import TournamentService from '../services/tournamentService'
import { Button, Tab, Tabs, AppBar, TableCell, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions, Dialog } from '@mui/material'
import Stack from '@mui/material/Stack';
import { Icon } from '@iconify/react';
import medalIcon from '@iconify-icons/mdi/medal';

import TournamentStructureArray from '../components/TournamentStructureArray'

import findMedalColorByRank from '../utils/findMedalColorByRank'
import InputSelect from '../components/InputSelect'

const structureOld = {
  details: [
  { type: 'ROUND', minutes: 1, smallBlind: 50, bigBlind: 100 },
  { type: 'ROUND', minutes: 1, smallBlind: 75, bigBlind: 150, ante: 0 },
  { type: 'PAUSE', minutes: 0, },
  { type: 'ROUND', minutes: 15, seconds: 0, smallBlind: 100, bigBlind: 200, ante: 200 }
]}

const membersColumns = [
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'pseudo', headerName: 'Pseudo', width: 130 },
  { field: 'killer', headerName: 'Eliminé par', whidth: 130 },
  { field: 'kill_count', headerName: 'Nombre de kills', whidth: 100 },
  { field: 'points', headerName: 'Points', width: 100}
]

export default class TournamentTimer extends Component {

  constructor(props) {
    super(props)

    this.onChangeMemberDialogInfos = this.onChangeMemberDialogInfos.bind(this)
    this.handleRankingTabChange = this.handleRankingTabChange.bind(this)
    this.handleStartTimerClick = this.handleStartTimerClick.bind(this)
    this.handlePauseTimerClick = this.handlePauseTimerClick.bind(this)
    this.handleResetTimerClick = this.handleResetTimerClick.bind(this)
    this.handleChangeCurrentRoundId = this.handleChangeCurrentRoundId.bind(this);

    this.handleCloseEliminateMemberDialog = this.handleCloseEliminateMemberDialog.bind(this)
    this.handleClickEliminateMember = this.handleClickEliminateMember.bind(this)
    this.handleConfirmMemberElimination = this.handleConfirmMemberElimination.bind(this)
    
    this.state = {
      tabvalue: 0,
      tournament: {},
      structure: { details: []},
      availableMembers: [],
      members: [],
      rankings: [],
      started: false,
      currentRoundId: 0,
      openDeleteDialog: false,
      timer: null,
      roundTimeLeft: 0,
      openEliminateMember: false,
      dialogMemberId: null,
      dialogKillerId: null
    }
  }

  handleRankingTabChange(event, newValue) {
    this.setState({ tabvalue: newValue})
  }

  handleStartTimerClick(e) {
    this.setState( {started: true })
  }

  handlePauseTimerClick = (e) => {
    this.setState({ pause : !this.state.pause })
  }

  handleResetTimerClick(e) {
    this.setState({ started : false, pause: false, currentRoundId: 0 })
  }

  handleChangeCurrentRoundId(newRound) {
    this.setState({ currentRoundId : newRound })
  }

  handleCloseEliminateMemberDialog(e) {
    this.setState({ dialogKillerId: null, dialogMemberId: null })
    this.setState({ openEliminateMember: false })
  }

  handleClickEliminateMember(e) {
    this.setState({ openEliminateMember: true })
  }

  handleConfirmMemberElimination(e) {
    // TODO Appeler le WS pour éliminer un membre + mettre à jour la liste des membres en live
    const clubId = this.props.match.params.clubId
    const { dialogMemberId, dialogKillerId } = this.state
    if (dialogMemberId === null || dialogKillerId === null) {
      return
    }
    console.log(this.state.dialogMemberId + " eliminé par : " + this.state.dialogKillerId)
    const id = this.props.match.params.id
      TournamentService.killMember(clubId, id, { memberId: dialogMemberId, killerId: dialogKillerId }).then((response) => {
        this.setState({ members: response.data.members })
        this.setState({ rankings: response.data.rankings })
    })
    this.setState({ openEliminateMember: false })
  }

  updateRoundTimeLeft(roundTimeLeft) {
    this.setState({ roundTimeLeft })
  }

  calculateRoundTimeLeft () {

    const { started, pause, currentRoundId, roundTimeLeft }= this.state
    const structure = this.state.structure.details

    if(structure.length === 0) {
      return 0
    }
    if (started) {
      if (pause) {
        return roundTimeLeft
      }
      if (roundTimeLeft === 0) {
        
        const currentRoundStructure = structure[currentRoundId + 1]
        this.handleChangeCurrentRoundId(currentRoundId + 1)
        return currentRoundStructure.minutes * 60
      }
      return roundTimeLeft - 1
    }
    const firstRound = structure[0]
    return firstRound.minutes * 60
  }

  findMembersList(currentMemberId) {
    return this.state.availableMembers
      .filter(member => this.state.members.find(m => m.id === member.id && (m.rank === undefined || m.rank === null)))
      .map(member => Object.assign({ value: member.id, text: member.pseudo }))
  }

  findMembersKillerList(currentMemberId) {
    return this.state.members
      .filter(member => member.id !== currentMemberId && (member.rank === undefined || member.rank === null))
      .map(member => Object.assign({ value: member.id || 'vide', text: member.pseudo }))
  }

  onChangeMemberDialogInfos(e, field) {
    if (field === 'member') {
      this.setState({ dialogMemberId : e.target.value })
    } else {
      this.setState({ dialogKillerId : e.target.value })
    }
  }

  componentDidMount() {
     // setTimeout(() => this.updateRoundTimeLeft(this.calculateRoundTimeLeft()), 1000)
    this.setState({ timer: setInterval(() => this.updateRoundTimeLeft(this.calculateRoundTimeLeft()), 1000)});

    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.getWithRankingDetails(clubId, id).then((response) => {
      this.setState({ tournament: response.data.tournament })
      this.setState({ members: response.data.members })
      this.setState({ availableMembers: response.data.members })
      this.setState({ rankings: response.data.rankings })
      this.setState({ structure: response.data.tournamentStructure })
    })
  }
  
  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  renderStartButton() {
    const started = this.state.started
    if (!started) {
      return <Button variant="contained" onClick={ this.handleStartTimerClick } color="primary">
      Commencer
      </Button>
    }
  }

  renderPauseButton() {
    if (!this.state.started) {
      return null
    }
    return (
    <Button variant="contained" onClick={ this.handlePauseTimerClick } color="primary">
      { this.state.pause === true ? "Reprendre" : "Pause" }
    </Button>)
  }

  renderResetButton() {
    return (
      <Button variant="contained" onClick={ this.handleResetTimerClick } color="primary">
        Reset
      </Button>
    )
  }

  render() {
    const { clubId, id } = this.props.match.params
    const { tournament, members, rankings, structure, started, pause, currentRoundId, roundTimeLeft, dialogMemberId, dialogKillerId }= this.state

    const dateTimeFormatOptions = {
      year: "numeric", month: "numeric", day: "numeric",
      hour: "numeric", minute: "numeric",
      hour12: false
    }
    const formattedDate = (tournament.date !== null && tournament.date !== undefined) ? new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(tournament.date)): null    
    
    return (
      <div>
        <AdminBlockquote clubId={ clubId } adminElements={
          (<Fragment>
            <Stack direction="row" spacing={1}>
              { this.renderStartButton() }
              { this.renderPauseButton() }
              { this.renderResetButton() }
              <Button variant="contained" onClick={ this.handleClickEliminateMember } color="error">
                Eliminer un joueur
              </Button>
            </Stack>
          </Fragment>) } >
        </AdminBlockquote>
        TournamentTimer
        <Dialog
          open={this.state.openEliminateMember}
          onClose={this.handleCloseEliminateMemberDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Suppression d'un tournoi"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Quel joueur à été éliminé ?
            </DialogContentText>
            <InputSelect 
              id={ `dialog_killed_member_id` }
              label="Membre éliminé"
              required= { true }
              value={ dialogMemberId || "" }
              availableValues={ this.findMembersList(dialogMemberId) }
              onInputChange={(e) => this.onChangeMemberDialogInfos(e, "member") }
            />
            <InputSelect 
              id={ `dialog_killer_member_id` }
              label="Killer"
              required= { true }
              value={ dialogKillerId || "" }
              availableValues={ this.findMembersList(dialogMemberId) }
              onInputChange={(e) => this.onChangeMemberDialogInfos(e, "killer") }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={ this.handleCloseEliminateMemberDialog } color="primary">
              Annuler
            </Button>
            <Button onClick={ this.handleConfirmMemberElimination } color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="transparent">
        <Tabs value={this.state.tabvalue} onChange={this.handleRankingTabChange} aria-label="simple tabs example" variant="scrollable">
          <Tab label="Timer" value={0}/>
          <Tab label="Structure" value={1}/>
          <Tab label="Membres" value={2}/>
          <Tab label="Classements" value={3}/>
        </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabvalue} index={0}>
          <Typography variant="h6">
            Date : { formattedDate }
          </Typography>
          <PokerTimer 
            structure = { structure } 
            pause = { pause }
            currentRoundId= { currentRoundId }
            roundTimeLeft = { roundTimeLeft }
            handleChangeCurrentRoundId = { this.handleChangeCurrentRoundId }
            >
          </PokerTimer>
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={1}>
        <TournamentStructureArray 
            structure = { structure }
            tournamentDate = { tournament.date }
            currentRoundId= { currentRoundId }
            started = { started }
          />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={2}>
          Membres TODO Pouvoir éliminer un membre directement ici, avec actualisation du classement en direct
          <TableWithPagination
          columns={ membersColumns }
          rows= { members }
          onRowClick={ this.handleMemberRowClick }
          cursorPointerOnHover={ true }
          rowDisplayMethod={(row) => {
            const medalColor = findMedalColorByRank(row.rank)
            const rank = (row.rank !== null && row.rank <= 3)?(
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}>
                <span style={{ 
                  marginRight: '10px'
                }}>  { row.rank }</span>
                <Icon icon={medalIcon} style={{ color: medalColor, fontSize: '24px'}}/>
              </div>
            ):(<div>{ row.rank }</div>)
            return (
              <Fragment>
                <TableCell key={`${row.id}_rank`}>
                  { rank }
                </TableCell>
                <TableCell key={`${row.id}_pseudo`}>
                  { row.pseudo }
                </TableCell>
                <TableCell key={`${row.id}_killer`}>
                  { row.killer }
                </TableCell>
                <TableCell key={`${row.id}_kill_count`}>
                  { row.killCount }
                </TableCell>
                <TableCell key={`${row.id}_points`}>
                  { row.points }
                </TableCell>
              </Fragment>
            )
          }}
        />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={3}>
          Classements à aller chercher et à mettre à jour au fur
          { JSON.stringify(rankings)}
        </TabPanel>
      </div>
    )
  }
}
