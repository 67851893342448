import React, { Component, Fragment } from 'react'
import { withStyles } from '@mui/styles';
import findMedalColorByRank from '../utils/findMedalColorByRank'

import { Link } from "react-router-dom"
import { AppBar, Avatar, Button, Tab, Tabs, TableCell, Typography } from '@mui/material'
import AdminBlockquote from '../components/AdminBlockquote'
import TabPanel from '../components/TabPanel'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TableWithPagination from '../components/TableWithPagination'
import { dateTimeFormatOptions } from '../utils/dateUtils'

import AuthService from '../services/authService'
import MemberService from '../services/memberService'

import { Icon } from '@iconify/react';
import trophyIcon from '@iconify-icons/mdi/trophy';
import medalIcon from '@iconify-icons/mdi/medal';



const styles  = (theme) => ({
  avatarWithPseudo: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    padding: '10px'
  }
})

const rankingsColumns = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'beginDate', headerName: 'Begin date', width: 130 },
  { field: 'endDate', headerName: 'End date', width: 130 } ,
  { field: 'rank', headerName: 'Place', width: 150 },
  { field: 'tournamentsCount', headerName: 'Tournois', width: 150 }
]

const tournamentsColumns = [
  { field: 'date', headerName: 'Date', width: 100 },
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'points', headerName: 'Points', width: 100 },
  { field: 'killer', headerName: 'Killer', width: 100 }
]

class Member extends Component {  
  constructor(props) {
    super(props)

    this.handleMemberTabChange = this.handleMemberTabChange.bind(this)
    this.handleRankingRowClick = this.handleRankingRowClick.bind(this)
    this.handleTournamentRowClick = this.handleTournamentRowClick.bind(this)
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this)
    this.handleClickDelete = this.handleClickDelete.bind(this)
    this.handleConfirmDeleteMember = this.handleConfirmDeleteMember.bind(this)

    this.state = {
      tabvalue: 0,
      member: {},
      tournaments: [],
      rankings: [],
      statistics: {},
      openDeleteDialog: false,
      messate: ''
    }
  }

  handleClickImThisMember(e) {
    e.preventDefault()
    const clubId = this.props.match.params.clubId
    const id = parseInt(this.props.match.params.id)
    const data = {
      userId: AuthService.getCurrentUser().id
    }
    MemberService.updateUserId(clubId, id, data).then(
      (result) => {
        AuthService.addMemberInClubAuthorities(clubId, id)
        // this.props.history.push(`/clubs/${clubId}/admin`)
        window.location.reload();
      },
      (error) => {
        const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.setState({
        loading: false,
        message: resMessage
      });
    })
  }

  handleMemberTabChange(event, newValue) {
    this.setState({ tabvalue: newValue})
  }

  handleRankingRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/rankings/' + id )
    window.location.reload()
  }

  handleTournamentRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id )
    window.location.reload()
  }

  handleCloseDeleteDialog(e) {
    this.setState({ openDeleteDialog: false })
  }

  handleClickDelete(e) {
    this.setState({ openDeleteDialog: true })
  }

  handleConfirmDeleteMember(e) {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    MemberService.delete(clubId, id).then((response) => {
      this.props.history.push('/clubs/' + this.props.match.params.clubId + '/members/')
      window.location.reload()
    },
    (error) => {
      if (error.response && error.response.data && error.response.data.error) {
        this.setState({ message: error.response.data.error })
      }
    })
    this.setState({ openDeleteDialog: false })
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    MemberService.getDetails(clubId, id).then((response) => {
      this.setState({ member: response.data.member })
      this.setState({ tournaments: response.data.tournaments })
      this.setState({ rankings: response.data.rankings })
      this.setState({ statistics: response.data.statistics })
    })
  }

  renderTop3Rankings() {
    const { rankings } = this.state
    return rankings.filter(tournament => tournament.rank <= 3)
    .sort((a, b) => {
      if (a.rank > b.rank) {
        return 1
      }
      if (a.rank < b.rank) {
        return -1
      }
      return 0
    })
    .map(tournament => {
      return (<Icon icon={ trophyIcon } style={{ color: findMedalColorByRank(tournament.rank), fontSize: '28px'}}/>)
    })
  }

  renderTop3Tournaments() {
    const { tournaments } = this.state
    return tournaments.filter(tournament => tournament.rank <= 3)
    .sort((a, b) => {
      if (a.rank > b.rank) {
        return 1
      }
      if (a.rank < b.rank) {
        return -1
      }
      return 0
    })
    .map(tournament => {
      return (<Icon icon={ medalIcon } style={{ color: findMedalColorByRank(tournament.rank), fontSize: '18px'}}/>)
    })
  }

  render() {
    const { classes } = this.props;
    const { member, rankings, tournaments, statistics }= this.state
    const { clubId, id } = this.props.match.params
    const isAdmin = AuthService.isClubAdmin(clubId)
    const isClubMember = AuthService.isClubMember(clubId)
    const isLogin = AuthService.isLogin()
    const isThisMember = AuthService.isMemberThisClub(clubId, id)
    const editMemberLink = (isAdmin || isThisMember)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/members/edit`,
      state: {
        id    
      }
    }}>
      Editer le membre
    </Button>) : null
    const imThisMemberButton = isClubMember === false && isLogin && member.userId === null ? 
      (<Button variant="contained" onClick={(e) => this.handleClickImThisMember(e) }>Je suis ce membre</Button>) : null
    const deleteMemberButton = (isAdmin) ? (
      <>
    <Button variant="contained" onClick={ this.handleClickDelete } color="error">
      Supprimer
    </Button>
    <Dialog
    open={this.state.openDeleteDialog}
    onClose={this.handleCloseDeleteDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{"Suppression d'un tournoi"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Êtes-vous sur de vouloir supprimer le membre ?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={ this.handleCloseDeleteDialog } color="primary">
        Non
      </Button>
      <Button onClick={ this.handleConfirmDeleteMember } color="primary" autoFocus>
        Oui
      </Button>
    </DialogActions>
  </Dialog>
  </>
    ) : null
    return (
      <div>
        <Typography variant="h4" className= { classes.avatarWithPseudo}>
          <Avatar variant="square" className={ classes.avatar } src={ window.location.origin + "/images/shark.svg" } sx={{ width: 130, height: 130 }} />
          { member.pseudo }
        </Typography>
        { imThisMemberButton }
        <blockquote>
          <Typography variant="h6">
            Palmarès
          </Typography>
          Classements : { this.renderTop3Rankings() }
          <br />
          Tournois : { this.renderTop3Tournaments() }
        </blockquote>
        <blockquote className="member-stats">
        <Typography variant="h6">
            Statistiques
        </Typography>
          Participation aux tournois : <strong>{ tournaments.length }</strong>
          <br />
          Nombre total de kills : <strong>{ statistics.killCount }</strong>
        </blockquote>
        <AdminBlockquote clubId={ clubId } adminElements={(<Fragment>{ editMemberLink } { deleteMemberButton } {this.state.message && (
              <div className='form-group'>
                <div className='alert alert-danger' role='alert'>
                  {this.state.message}
                </div>
              </div>
            )}</Fragment>)} />
        <AppBar position="static" color="transparent">
        <Tabs value={this.state.tabvalue} onChange={this.handleMemberTabChange} aria-label="simple tabs example" variant="scrollable">
          <Tab label="Classements" value={0}/>
          <Tab label="Tournois" value={1}/>
        </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabvalue} index={0}>
          <TableWithPagination
          columns={ rankingsColumns }
          rows= { rankings }
          onRowClick={ this.handleRankingRowClick }
          rowDisplayMethod={(row) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_rank`}>
                      { row.name }
                    </TableCell>
                    <TableCell key={`${row.id}_begin_date`}>
                      {  new Intl.DateTimeFormat('fr-FR').format(new Date(row.begin_date )) }
                    </TableCell>
                    <TableCell key={`${row.id}_end_date`}>
                      {  new Intl.DateTimeFormat('fr-FR').format(new Date(row.end_date )) }
                    </TableCell>
                    <TableCell key={`${row.id}_ranking`}>
                      { `${row.rank} / ${row.memberCount}` }
                    </TableCell>
                    <TableCell key={`${row.id}_tournament_count`}>
                    { `${row.memberTournamentCount} / ${row.tournamentCount}` }
                    </TableCell>
              </Fragment>
            )
          }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={1}>
          <TableWithPagination
          columns={ tournamentsColumns }
          rows= { tournaments }
          onRowClick={ this.handleTournamentRowClick }
          rowDisplayMethod={(row) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_date`}>
                {
                  new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(row.date))
                }
                </TableCell>
                <TableCell key={`${row.id}_rank`}>
                  { `${row.rank} / ${row.memberCount}` }
              </TableCell>
                <TableCell key={`${row.id}_points`}>
                  { row.points }
                </TableCell>
                <TableCell key={`${row.id}_killer`}>
                  { row.killer }
                </TableCell>
              </Fragment>
            )
          }}
          />
        </TabPanel>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Member);