import { TextField } from '@mui/material'
import React, { Component } from 'react'

class InputDateTime extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onInputChange(e)
  }
  
  render() {
    const { id, label, name, required, autoComplete, autoFocus, value } = this.props
    return (
      <TextField
        id={ id }
        label={ label }
        name={ name }
        required={ required }
        autoComplete={ autoComplete }
        autoFocus={ autoFocus }
        margin="normal"
        fullWidth
        type="datetime-local"
        value={ value }
        InputLabelProps={{
          shrink: true,
        }}
        onChange={ this.handleChange }
      />
    )
  }
}

export default InputDateTime
