import React from 'react'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AuthService from '../services/authService'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}))

export default function AdminBlockquote(props) {
  const classes = useStyles();

  const { clubId, adminElements } = props

  const isAdmin = () => {
    return AuthService.isClubAdmin(clubId) 
  }
  return (
    <blockquote className="admin-blockquote" hidden={ !isAdmin() }>
      <Typography variant="h6">
        Administration
      </Typography>
      <div className={classes.root}>
        { adminElements }
      </div>
    </blockquote>
  )
}
