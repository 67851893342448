import React, { Component } from 'react'
import Form from 'react-validation/build/form';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputNumber from '../components/InputNumber';
import InputSelect from '../components/InputSelect';

import TournamentService from '../services/tournamentService';

import { Icon } from '@iconify/react';
import deleteIcon from '@iconify-icons/mdi/delete';
import upIcon from '@iconify-icons/mdi/arrow-up';
import downIcon from '@iconify-icons/mdi/arrow-bottom';

const columns = [
  { field: 'member', headerName: 'Membre', width: 130 },
  { field: 'tableId', headerName: 'Numéro de table', width: 130 },
  { field: 'actions', headerName: 'Actions', width: 300 }
]

class DivideTournamentMembers extends Component {
  constructor(props) {
    super(props)
    this.onChangeTableCount = this.onChangeTableCount.bind(this);
    this.calculateMembersByTable = this.calculateMembersByTable.bind(this)
    this.handleChangeRankings = this.handleChangeRankings.bind(this);
    this.onChangeMemberInfos = this.onChangeMemberInfos.bind(this);
    this.handleClickAddMember = this.handleClickAddMember.bind(this)
    this.clearMembersTable = this.clearMembersTable.bind(this)
    this.shuffleMembersTable = this.shuffleMembersTable.bind(this)
    this.shuffleEmptyMembersTable = this.shuffleEmptyMembersTable.bind(this)
    this.handleEditTournament = this.handleEditTournament.bind(this)
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this)
    this.handleClickUpMember = this.handleClickUpMember.bind(this)
    this.handleClickDownMember = this.handleClickDownMember.bind(this)
    this.state = {
      availableRankings: [],
      availableMembers: [],
      rankings: [],
      members: [],
      loading: false,
      message: '',
      tableCount: 0,
      maxMembersByTable: 0,
      minMembersByTable: 0
    }
  }

  onChangeTableCount(e) {
    this.setState({ tableCount: e.target.value })
    this.calculateMembersByTable(e.target.value)
  }

  handleClickAddMember(e) {
    const members = this.state.members
    members.push({ rank: members.length + 1 })
    this.setState({ members })
    this.calculateMembersByTable(this.state.tableCount)
  }

  calculateMembersByTable(tableCount) {
    const membersCount = this.state.members.length

    const minMembersByTable = Math.floor(membersCount / tableCount)
    this.setState({ minMembersByTable })

    if (membersCount % tableCount > 0) {
      this.setState({ maxMembersByTable: minMembersByTable + 1 })
    } else {
      this.setState({ maxMembersByTable: minMembersByTable })
    }
  }

  handleChangeRankings(e, newValue) {
    this.setState({ rankings: newValue })
  }

  onChangeMemberInfos(e, index, field) {
    const members = this.state.members
    members[index][field] = e.target.value
    if (field === 'id') {
      members[index].pseudo = this.state.availableMembers.find(member => member.id === e.target.value).pseudo
      if (members[index].killerId === undefined) {
        members[index].killerId = null
      }
    }
    this.setState({ members })
  }

  handleClickDeleteMember(e, index) {
    const members = this.state.members
    members.splice(index, 1);
    this.setState({
      members: members.map((member, index) => {
        return Object.assign(member, { rank: index + 1 })
      })
    })
  }

  handleClickUpMember(e, fromIndex) {
    if (fromIndex > 0) {
      this.moveMember(fromIndex, fromIndex - 1)
    }
  }

  handleClickDownMember(e, fromIndex) {
    const members = this.state.members
    if (fromIndex < members.length - 1) {
      this.moveMember(fromIndex, fromIndex + 1)
    }
  }

  moveMember(fromIndex, toIndex) {
    const members = this.state.members
    const member = members[fromIndex];
    members.splice(fromIndex, 1);
    members.splice(toIndex, 0, member);
    this.setState({ members })
  }

  handleEditTournament(e) {
    e.preventDefault()
    const { members } = this.state
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    TournamentService.divideMembers(clubId, id, { members }).then((response) => {
      this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id)
      window.location.reload()
    })
  }

  findMembersList(currentMember) {
    return this.state.availableMembers
      .filter(member => this.state.members.find(m => m.id === member.id && m.id !== currentMember.id) === undefined)
      .map(member => Object.assign({ value: member.id, text: member.pseudo }))
  }

  findTableIds(currentMember) {
    const tableIds = []
    const tableCount = this.state.tableCount
    const maxMembersByTable = this.state.maxMembersByTable
    for (let index = 1; index <= tableCount; index++) {
      const tableMembers = this.state.members.filter(m => m.tableId === index).length
      if ((tableMembers < maxMembersByTable && currentMember.tableId !== index) || currentMember.tableId === index) {
        tableIds.push({ value: index, text: `Table ${index}` })
      }
    }
    return tableIds
  }

  clearMembersTable() {
    let members = this.state.members
    members = members.map(m => { return Object.assign(m, { tableId: undefined }) })
    this.setState({ members: members.map(m => { return Object.assign(m, { tableId: undefined }) }) })
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  shuffleMembersTable() {
    this.clearMembersTable()
    this.shuffleEmptyMembersTable()  
  }

  shuffleEmptyMembersTable() {
    
    let members = this.shuffle(this.state.members)
    const tableCount = this.state.tableCount
    if (tableCount === 0 || tableCount === undefined) {
      return
    }
    let currentTable = 1
    console.log(members)
    for (let index = 0; index < members.length; index++) {
      const member = members[index];
      currentTable = this.findNextTableId(member, currentTable)
      if (member.tableId === undefined) {
        member.tableId = currentTable;
      }
      currentTable++
      if (currentTable > tableCount) {
        currentTable = 1
      }
    }
    this.setState({ members: members.sort((a, b) => a.tableId - b.tableId ) })
  }

  findNextTableId(member, currentTable) {
    let nextTable = currentTable
    const availableTables = this.findTableIds(member)

    while(true) {
      if (availableTables.find(table => table.value === nextTable) !== undefined) {
        return nextTable
      }
      nextTable++
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    TournamentService.prepare(clubId).then((response) => {
      this.setState({ availableMembers: response.data.members })
      this.setState({
        availableRankings: response.data.rankings.map(ranking => {
          return {
            id: ranking.id,
            name: ranking.name
          }
        })
      })
      const clubId = this.props.match.params.clubId
      const id = this.props.match.params.id
      TournamentService.get(clubId, id).then((response) => {
        this.setState({ date: new Date(response.data.tournament.date).toISOString().slice(0, 16) })
        this.setState({ members: response.data.members })
        this.setState({
          rankings: response.data.rankings.map(ranking => {
            return {
              id: ranking.id,
              name: ranking.name
            }
          })
        })
      })
    })
  }

  render() {
    const { date, members } = this.state
    const dateTimeFormatOptions = {
      year: "numeric", month: "numeric", day: "numeric",
      hour: "numeric", minute: "numeric",
      hour12: false
    }
    const formattedDate = (date !== null && date !== undefined) ? new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(date)) : null

    return (
      <div>
        <h1>Répartition des membres en plusieurs SNG le : {formattedDate}</h1>
        <h3>Nombre de joueurs : {this.state.members.length}</h3>
        <Form onSubmit={this.handleEditTournament} >
          <InputNumber
            required={false}
            id="tableCount"
            type="number"
            label="Nombre de table"
            name="tableCount"
            autoFocus={false}
            value={this.state.tableCount || ""}
            isOnlyInteger={true}
            isOnlyPositive={true}
            onInputChange={(e) => this.onChangeTableCount(e)}
          />

          <h5>
            Nombre de joueurs minimum par table : {this.state.minMembersByTable} <br />
            Nombre de joueurs maximum par table : {this.state.maxMembersByTable}
          </h5>
          <Button variant="contained" onClick={this.handleClickAddMember}>Ajouter un membre</Button>
          <Button variant="contained" onClick={this.clearMembersTable}>Vider les tables</Button>
          <Button variant="contained" onClick={this.shuffleMembersTable}>Assigner les tables</Button>
          <Button variant="contained" onClick={this.shuffleEmptyMembersTable}>Assigner les tables restantes</Button>
          <Paper style={{ height: '80%', width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {members.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={`member_${index}`}>
                        <TableCell>
                          <InputSelect
                            id={`${index}_member_id`}
                            label="member"
                            required={true}
                            value={row.id || ""}
                            availableValues={this.findMembersList(row)}
                            onInputChange={(e) => this.onChangeMemberInfos(e, index, "id")}
                          />
                        </TableCell>
                        <TableCell>
                          <InputSelect
                            id={`${index}_tableId`}
                            label="Table"
                            required={true}
                            value={row.tableId || ""}
                            availableValues={this.findTableIds(row)}
                            onInputChange={(e) => this.onChangeMemberInfos(e, index, "tableId")}
                          />
                        </TableCell>

                        <TableCell>
                          <IconButton
                            aria-label="Supprimer"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => this.handleClickDeleteMember(e, index)}
                            color="inherit"
                          >
                            <Icon icon={deleteIcon} style={{ fontSize: '18px' }} />
                          </IconButton>

                          <IconButton
                            aria-label="Monter"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => this.handleClickUpMember(e, index)}
                            color="inherit"
                          >
                            <Icon icon={upIcon} style={{ fontSize: '18px' }} />
                          </IconButton>

                          <IconButton
                            aria-label="Descendre"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => this.handleClickDownMember(e, index)}
                            color="inherit"
                          >
                            <Icon icon={downIcon} style={{ fontSize: '18px' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}

export default DivideTournamentMembers