import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom"

import TableCell from '@mui/material/TableCell'

import { Icon } from '@iconify/react'
import trophyIcon from '@iconify-icons/mdi/trophy'
import medalIcon from '@iconify-icons/mdi/medal'
import sharkIcon from '@iconify-icons/mdi/shark-fin'
import bubbleIcon from '@iconify/icons-ic/outline-bubble-chart'
import { Button, Tab, Tabs, AppBar, Typography } from '@mui/material'

import AuthService from '../services/authService'
import RankingService from '../services/rankingService'
import AdminBlockquote from '../components/AdminBlockquote'
import TabPanel from '../components/TabPanel'

import TableWithPagination from '../components/TableWithPagination'
import { dateTimeFormatOptions } from '../utils/dateUtils'
import findMedalColorByRank from '../utils/findMedalColorByRank'
import findBubbleColor from '../utils/findBubbleColor'
import Top3 from '../components/Top3';

const membersColumns = [
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'games', headerName: 'Parties', width: 100 },
  { field: 'points', headerName: 'Points', width: 100}
]

const killerRankingColumns = [
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'pseudo', headerName: 'Pseudo', width: 130 },
  { field: 'killCount', headerName: 'Nombre de kills', width: 100}
]

const tournamentsColumns = [
  { field: 'date', headerName: 'Date', width: 100 },
  { field: 'participantCount', headerName: 'Participants', width: 100 },
  { field: 'top3', headerName: 'TOP 3', width: 100 }
]

export default class Ranking extends Component {
  constructor(props) {
    super(props)

    this.handleMemberRowClick = this.handleMemberRowClick.bind(this)
    this.handleTournamentRowClick = this.handleTournamentRowClick.bind(this)
    this.handleRankingTabChange = this.handleRankingTabChange.bind(this)

    this.state = {
      tabvalue: 0,
      ranking: { beginDate: new Date(), endDate: new Date() },
      members: [],
      killerRanking: [],
      tournaments: [],
      sessionCount: 0
    }
  }

  handleRankingTabChange(event, newValue) {
    this.setState({ tabvalue: newValue})
  }

  handleMemberRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/members/' + id )
    window.location.reload()
  }

  handleTournamentRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id )
    window.location.reload()
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = this.props.match.params.id
    RankingService.getDetails(clubId, id).then((response) => {
      this.setState({ ranking: response.data.ranking })
      this.setState({ members: response.data.members })
      this.setState({ killerRanking: response.data.killerRanking })
      this.setState({ tournaments: response.data.tournaments })
      this.setState({ sessionCount: response.data.sessionCount })
    })
  }
  
  render() {
    const { clubId, id } = this.props.match.params
    const isAdmin = AuthService.isClubAdmin(clubId)
    const editRankingLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/rankings/edit`,
      state: {
        id    
      }
    }}>
      Editer le classement
    </Button>) : null
    const { ranking, members, killerRanking, tournaments, sessionCount } = this.state
    const beginDateFormatted = ranking.beginDate ? new Intl.DateTimeFormat('fr-FR').format(new Date(ranking.beginDate)) : null
    const endDateFormatted = ranking.beginDate ? new Intl.DateTimeFormat('fr-FR').format(new Date(ranking.endDate)) : null
    const goldColor = findMedalColorByRank(1)
    const silverColor = findMedalColorByRank(2)
    const bronzeColor = findMedalColorByRank(3)
    return (
      <div>
        <Typography variant="h4">
          { ranking.name }
        </Typography>
        <blockquote>
          <Typography variant="h6">
            Informations
          </Typography>
          Date de début : <strong>{  beginDateFormatted }</strong> 
          <br />
          Date de fin : <strong>{  endDateFormatted }</strong>
        </blockquote>
        <AdminBlockquote clubId={ clubId } adminElements={(<Fragment>{ editRankingLink }</Fragment>)} />        
        <AppBar position="static" color="transparent">
        <Tabs value={this.state.tabvalue} onChange={this.handleRankingTabChange} aria-label="simple tabs example" variant="scrollable">
          <Tab label="Classement général" value={0}/>
          <Tab label="Classement killer" value={1}/>
          <Tab label="Tournois" value={2}/>
        </Tabs>
        </AppBar>
        <TabPanel value={this.state.tabvalue} index={0}>
          <TableWithPagination
            columns={ membersColumns }
            rows= { members }
            onRowClick={ this.handleMemberRowClick }
            cursorPointerOnHover={ true }
            rowDisplayMethod={(row) => {
              let medalColor = findMedalColorByRank(row.rank)  
              const rank = (row.rank <= 3)?(
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                  <Icon icon={trophyIcon} style={{ color: medalColor, fontSize: '24px', marginRight: '10px'}}/>
                  <span>{ row.rank } { row.pseudo }</span>
                  
                </div>
              ):(<div>{ row.rank } { row.pseudo }</div>)
              return (
                <Fragment>
                  <TableCell key={`${row.id}_rank`}>
                      { rank }
                    </TableCell>
                    <TableCell key={`${row.id}_games`}>
                      { row.participationCount } / { sessionCount }
                      <div style={{
                        display: 'flex',
                        flexWrap: 'wrap'
                      }}>  
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: goldColor, fontSize: '18px'}}/> 
                          { row.top3.top1 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: silverColor, fontSize: '18px'}}/>
                          { row.top3.top2 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={medalIcon} style={{ color: bronzeColor, fontSize: '18px'}}/>
                          { row.top3.top3 }
                        </div>
                        <div style={{ marginRight: '10px', display: 'flex' }}>
                          <Icon icon={bubbleIcon} style={{ color: findBubbleColor(), fontSize: '18px'}}/>
                          { row.top3.bubble }
                        </div>
                        
                      </div>
                    </TableCell>
                    <TableCell key={`${row.id}_points`}>
                      { row.points }
                    </TableCell>
                </Fragment>
              )
            }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={1}>
          <TableWithPagination
            columns={ killerRankingColumns }
            rows= { killerRanking }
            onRowClick={ this.handleMemberRowClick }
            cursorPointerOnHover={ true }
            rowDisplayMethod={(row, index) => {
              const killerRank = row.rank
              let medalColor = findMedalColorByRank(killerRank)  
              const rank = (killerRank <= 3)?(
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}>
                  <span style={{ 
                    marginRight: '10px'
                  }}>  { killerRank }</span>
                  <Icon icon={ sharkIcon } style={{ color: medalColor, fontSize: '24px'}}/>
                </div>
              ):(<div>{ killerRank }</div>)
              return (
                <Fragment>
                  <TableCell key={`${row.id}_rank`}>
                      { rank }
                    </TableCell>
                    <TableCell key={`${row.id}_pseudo`}>
                      { row.pseudo }
                    </TableCell>
                    <TableCell key={`${row.id}_kill_count`}>
                      { row.killCount }
                    </TableCell>
                </Fragment>
              )
            }}
          />
        </TabPanel>
        <TabPanel value={this.state.tabvalue} index={2}>
          <TableWithPagination
            columns={ tournamentsColumns }
            rows= { tournaments }
            onRowClick={ this.handleTournamentRowClick }
            cursorPointerOnHover={ true }
            rowDisplayMethod={(row) => {
              return (
                <Fragment>
                  <TableCell key={`${row.id}_date`}>
                      {
                        new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(new Date(row.date))
                      }
                    </TableCell>
                    <TableCell key={`${row.id}_member_count`}>
                      { row.memberCount }
                    </TableCell>
                    <TableCell key={`${row.id}_top_3`}>
                      <Top3 top3= { row.top3 } />
                    </TableCell>
                </Fragment>
              )
            }}
          />
        </TabPanel>
      </div>
    )
  }
}
