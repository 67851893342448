import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

export default function RankingSelector(props) {
  const { values, options, id } = props

  const handleChange = (event, newValue) => {
    props.onChange(event, newValue)
  }

  return (
    <Autocomplete
      multiple
      id= { id }
      options={ options }
      value={ values }
      onChange={ handleChange }
      getOptionLabel={ (option) => option.name }
      getOptionSelected={(option, value) => option.id === value.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
        return (
          <Chip
            label={option.name}
            color="primary"
            {...getTagProps({ index })}
          />
        )
        })
      }
      style={{ width: '100%' }}
      renderInput={(params) => (
        <TextField {...params} label="Classements" variant="standard" placeholder="Classements" />
      )}
    />
  );
}
