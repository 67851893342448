import React, { Component } from 'react'
import { FormControl, IconButton, InputAdornment, InputLabel, Input } from '@mui/material';
import { Icon } from '@iconify/react';
import eyeIcon from '@iconify-icons/mdi/eye';
import eyeIconOff from '@iconify-icons/mdi/eye-off';


class InputPassword extends Component {
  constructor(props) {
    super(props)
  
    this.handleChange = this.handleChange.bind(this)
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this)

    this.state = {
       showPassword: false
    }
  }
  
  handleChange(e) {
    this.props.onInputChange(e)
  }

  handleClickShowPassword(e) {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }

  render() {
    const { id, label, value } = this.props
    return (
      <FormControl variant="standard" fullWidth margin="normal">
        <InputLabel htmlFor={ id } required>{ label }</InputLabel>
        <Input
          id={ id }
          type={this.state.showPassword ? 'text' : 'password'}
          variant="standard"
          required
          fullWidth
          onChange={ this.handleChange }
          label={ label }
          value= { value }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={ this.handleClickShowPassword }
                edge="end"
              >
                {this.state.showPassword ? <Icon icon={eyeIcon} /> : <Icon icon={eyeIconOff} />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    )
  }
}

export default InputPassword