import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    height: "100%",
    maxWidth: 345,
  },
});

 const ClubCard = (props) => {
  const history = useHistory()
  const classes = useStyles()

  const handleCardClubClick = () => {
    history.push('/clubs/'+ props.id)
  }

  const handleClickTournaments = () => {
    history.push('/clubs/' + props.id + '/tournaments')
    window.location.reload()
  };

  const handleClickMembers = () => { 
    history.push('/clubs/' + props.id + '/members')
    window.location.reload()
  }

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={ handleCardClubClick }
      >
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={ window.location.origin + "/images/poker-club.png" }
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            { props.name }
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Nombre de membres : XXX <br />
            Nombre de tournois : XXX
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={ handleClickTournaments }>
          Tournois
        </Button>
        <Button size="small" color="primary" onClick={ handleClickMembers }>
          Membres
        </Button>
      </CardActions>
    </Card>
  );
}

export default ClubCard