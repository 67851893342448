import React, { Component } from 'react'

import Form from 'react-validation/build/form';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputText from '../components/InputText'
import InputNumber from '../components/InputNumber';
import InputSelect from '../components/InputSelect';

import AdminService from '../services/adminService'

import { Icon } from '@iconify/react';
import deleteIcon from '@iconify-icons/mdi/delete';
import upIcon from '@iconify-icons/mdi/arrow-up';
import downIcon from '@iconify-icons/mdi/arrow-bottom';


const columns = [
  { field: 'type', headerName: 'Type', width: 100 },
  { field: 'detail', headerName: 'Détail', width: 130 },
  { field: 'actions', headerName: 'Actions', width: 300 }
]

const tournamentStructureTypeSelectList = [
    { value: 'ROUND', text: 'Round' },
    { value: 'PAUSE', text: 'Pause' }
  ]

export default class EditTournamentStructure extends Component {

  constructor(props) {
    super(props)
    this.handleEditTournamentStructure = this.handleEditTournamentStructure.bind(this)
    this.handleClickAddDetail = this.handleClickAddDetail.bind(this)
    this.onChangeName = this.onChangeName.bind(this)
    this.onChangeDetailInfos = this.onChangeDetailInfos.bind(this)
    this.handleClickDeleteDetail = this.handleClickDeleteDetail.bind(this)
    this.handleClickUpDetail = this.handleClickUpDetail.bind(this)
    this.handleClickDownDetail = this.handleClickDownDetail.bind(this)
    
    
    this.state = {
      tournamentStructure: {},
      details: []
    }
  }

  onChangeName(e) {
    const tournamentStructure = this.state.tournamentStructure
    tournamentStructure.name = e.target.value
    this.setState({ tournamentStructure })
  }

  handleClickAddDetail(e) {
    const details = this.state.details
    details.push({ order: details.length + 1 })
    this.setState({ details })
  }

  onChangeDetailInfos(e, index, field) {
    const details = this.state.details
    details[index][field] = e.target.value
    this.setState({ details })
  }

  handleClickDeleteDetail(e, index) {
    const details = this.state.details
    details.splice(index, 1);
    this.setState({ details: details.map((detail, index) => {
      return Object.assign(detail, { order: index + 1 })
    })})
  }

  handleClickUpDetail(e, fromIndex) {
    if (fromIndex > 0) {
      this.moveDetail(fromIndex, fromIndex - 1)
    }
  }

  handleClickDownDetail(e, fromIndex) {
    const details = this.state.details
    if (fromIndex < details.length - 1) {
      this.moveDetail(fromIndex, fromIndex + 1)
    }
  }

  moveDetail(fromIndex, toIndex) {
    const details = this.state.details
    const detail = details[fromIndex];
    details.splice(fromIndex, 1);
    details.splice(toIndex, 0, detail);
    this.setState({ details })
  }

  handleEditTournamentStructure(e) {
    e.preventDefault()
    const clubId = this.props.match.params.clubId
    const id = parseInt(this.props.match.params.id)
    const details = this.state.details
    
    details.map((detail, index) => {
      detail.roundOrder = index + 1
    })

    const data = Object.assign({ details: this.state.details }, this.state.tournamentStructure)

    if (id === 0) {
      AdminService.createTournamentStructure(clubId, data).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/admin`)
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      })
    } else {
      AdminService.updateTournamentStructure(clubId, id, data).then(
        (result) => {
          this.props.history.push(`/clubs/${clubId}/admin`)
          window.location.reload();
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
  
        this.setState({
          loading: false,
          message: resMessage
        });
      })
    }
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    const id = parseInt(this.props.match.params.id)
    if (id === 0) {
      return
    }
    AdminService.getTournamentStructure(clubId, id).then((response) => {
      this.setState({ tournamentStructure: response.data.tournamentStructure })
      this.setState({ details: response.data.details })
    })
  }

  renderRoundDetail(row, index) {
    const detail = this.state.details[index]
    
    if (detail.type === 'ROUND') {
      return (<table>
        <tr>
          <td>
        <InputNumber 
          id={ `${index}_detail_small_blind` }
          label="Small blind"
          required= { true }
          value={ row.smallBlind || "" }
          onInputChange={(e) => this.onChangeDetailInfos(e, index, "smallBlind") }
        />
        </td>
        <td>
        <InputNumber 
          id={ `${index}_detail_big_blind` }
          label="Big blind"
          required= { true }
          value={ row.bigBlind || "" }
          onInputChange={(e) => this.onChangeDetailInfos(e, index, "bigBlind") }
        />
        </td>
        <InputNumber 
          id={ `${index}_detail_ante` }
          label="Ante"
          required= { false }
          value={ row.ante || "" }
          onInputChange={(e) => this.onChangeDetailInfos(e, index, "ante") }
        />
        </tr>
      </table>)
    }
    return null
  }

  render() {
    const { tournamentStructure, details } = this.state
    const clubId = this.props.match.params.clubId
    const id = parseInt(this.props.match.params.id)
    return (
      <div>
        EditTournamentStructure
        { clubId } <br />
        { id }
        <Form onSubmit={ this.handleEditTournamentStructure } >
        <InputText
            required={ true }
            id="name"
            label="Nom"
            name="Nom"
            autoComplete="Nom"
            autoFocus={ true }
            value={ tournamentStructure?.name || "" }
            onInputChange={ this.onChangeName }
          />
          <Button variant="contained" onClick={ this.handleClickAddDetail }>Ajouter un round</Button>
          <Paper style={{ height: '80%', width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                { details.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`detail_${index}`}>
                    <TableCell>
                    <InputSelect 
                      id="type"
                      label="Type"
                      required= { true }
                      value={ row.type || "" }
                      availableValues={ tournamentStructureTypeSelectList }
                      onInputChange={(e) => this.onChangeDetailInfos(e, index, "type") }
                    />
                    </TableCell>
                    <TableCell>
                      <InputNumber 
                        id={ `${index}_detail_minutes` }
                        label="minutes"
                        required= { true }
                        value={ row.minutes || "" }
                        onInputChange={(e) => this.onChangeDetailInfos(e, index, "minutes") }
                      />
                      { this.renderRoundDetail(row, index) }
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Supprimer"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickDeleteDetail(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ deleteIcon } style={{ fontSize: '18px'}} />
                      </IconButton>

                      <IconButton
                        aria-label="Monter"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickUpDetail(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ upIcon } style={{ fontSize: '18px'}} />
                      </IconButton>

                      <IconButton
                        aria-label="Descendre"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickDownDetail(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ downIcon } style={{ fontSize: '18px'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
