import React, { Component } from 'react'
import TextField from '@mui/material/TextField';

class InputText extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange(e) {
    this.props.onInputChange(e)
  }

  render() {
    const { fullWidth, id, label, name, required, autoComplete, autoFocus, value, type } = this.props
    return (
      <TextField
            variant="standard"
            margin="normal"
            fullWidth= { fullWidth !== undefined ? fullWidth : true }
            id={ id }
            type={ type || "text" }
            label={ label }
            name={ name }
            required={ required }
            autoComplete={ autoComplete }
            autoFocus={ autoFocus }
            value={ value }
            onChange={ this.handleChange }
          />
    )
  }
}

export default InputText
