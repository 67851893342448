import React, { Component } from 'react'
import AuthService from '../services/authService';

export default class Profile extends Component {
  render() {
    const currentUser = AuthService.getCurrentUser()
    return (
      <div>
        Profile PAGE { currentUser.username }
      </div>
    )
  }
}
