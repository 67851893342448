import React from 'react';
import clsx from 'clsx';
import { Avatar, Box, Card, CardActionArea, CardContent, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(({ palette }) => ({
  card: {
    borderRadius: 12,
    minWidth: 256,
    textAlign: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: 'auto',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    marginTop: 8,
    marginBottom: 0,
  },
  subheader: {
    fontSize: 14,
    color: palette.grey[500],
    marginBottom: '0.875em',
  },
  statLabel: {
    fontSize: 12,
    color: palette.grey[500],
    fontWeight: 500,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    margin: 0,
  },
  statValue: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 4,
    letterSpacing: '1px',
  },
}));

 const MemberCard = (props) => {
  const styles = useStyles();
  const history = useHistory()
  const handleCardMemberClick = () => { 
    history.push('/clubs/' + props.clubId + '/members/' + props.id )
    window.location.reload()
  }
  return (
    <div>
      <Card className={clsx(styles.card)}>
        <CardActionArea
          onClick={ handleCardMemberClick }
        >
          <CardContent>
            <Avatar variant="square" className={styles.avatar} src={ window.location.origin + "/images/shark.svg" } />
            <h3 className={styles.heading}>{ props.pseudo }</h3>
          </CardContent>
          <Divider light />
          <Box display={'flex'}>
            <Box p={2} flex={'auto'}>
              <p className={styles.statLabel}>Tournois</p>
              <p className={styles.statValue}>{ props.tournamentCount }</p>
            </Box>
            <Box p={2} flex={'auto'}>
              <p className={styles.statLabel}>Kills</p>
              <p className={styles.statValue}>{ props.killCount }</p>
            </Box>
          </Box>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default MemberCard