import axios from 'axios'
import tokenService from './tokenService'
import { history } from "../index";

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_API_URL,
  headers: {
    "Content-type": "application/json"
  }
})

instance.interceptors.request.use(
  (config) => {
    const token = tokenService.getLocalAccessToken()
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["x-access-token"] = token // for Node.js Express back-end
    }
    return config
  },
  (error) => {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = await instance.post("/auth/refreshtoken", {
            refreshToken: tokenService.getLocalRefreshToken(),
          })

          const { accessToken } = rs.data
          tokenService.updateLocalAccessToken(accessToken)

          return instance(originalConfig)
        } catch (_error) {
          tokenService.removeUser()
          // redirect to login page
          history.push({
            pathname: '/login',
            state: { 
              logoutMessage: 'Vous avez été déconnecté veuillez vous reconnecter',
              redirect : history.location
            }
          })
          window.location.reload()
          return Promise.reject(_error)
        }
      }
    }
    return Promise.reject(err)
  }
)

export default instance