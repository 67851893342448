import React, { Component, Fragment } from 'react'
import Button from '@mui/material/Button'
import { Link } from "react-router-dom";
import TableCell from '@mui/material/TableCell'
import InputCheckBox from '../components/InputCheckBox'
import InputSelect from '../components/InputSelect'
import TableWithPagination from '../components/TableWithPagination'
import MemberService from '../services/memberService'
import AdminService from '../services/adminService'

const membersColumns = [
  { field: 'username', headerName: 'Utilisateur', width: 200 },
  { field: 'pseudo', headerName: 'Pseudo du membre', width: 130 },
  { field: 'role', headerName: 'Role', width: 130 },
  { field: 'validated', headerName: 'Validé', width: 130 }
]

const pointCalculationMethodsColumns = [
  { field: 'name', headerName: 'Nom', width: 200 },
  { field: 'type', headerName: 'Type', width: 100 }
]

const tournamentStructureColumns = [
  { field: 'name', headerName: 'Nom', width: 200 },
  { field: 'type', headerName: 'Type', width: 100 }
]

const memberRolesSelectList = [
  { value: 'ADMIN', text: 'Administrateur' },
  { value: 'MEMBER', text: 'Membre' }
]

export default class ClubAdmin extends Component {
  constructor(props) {
    super(props)
    this.handleChangeUserRole = this.handleChangeUserRole.bind(this)
    this.handleClickSaveUserRole = this.handleClickSaveUserRole.bind(this)
    this.handleValidateUserMember = this.handleValidateUserMember.bind(this)
    this.handleClickValidateUserMember = this.handleClickValidateUserMember.bind(this)
    this.handleRowPointCalculationMethodClick = this.handleRowPointCalculationMethodClick.bind(this)
    this.handleRowTournamentStructureClick = this.handleRowTournamentStructureClick.bind(this)
    this.state = {
      members: [],
      pointCalculationMethods: [],
      tournamentStructure: []
   }
  }

  handleChangeUserRole(e, index) {
    const members = this.state.members
    members[index].role = e.target.value
    this.setState({ members })
  }

  handleClickSaveUserRole(e, index) {
    e.preventDefault()
    const members = this.state.members
    const member = members[index]
    const clubId = this.props.match.params.clubId
    const id = member.id
    const data = { userRole: member.role }
    AdminService.updateUserRole(clubId, id, data).then(
      (result) => {
        this.props.history.push(`/clubs/${clubId}/admin`)
        window.location.reload();
      },
      (error) => {
        const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.setState({
        loading: false,
        message: resMessage
      });
    })
  }

  handleValidateUserMember(e, index) {
    const members = this.state.members
    members[index].validated = e.target.checked
    this.setState({ members })
  }

  handleClickValidateUserMember(e, index) {
    e.preventDefault()
    const members = this.state.members
    const member = members[index]
    const clubId = this.props.match.params.clubId
    const id = member.id
    const data = { userRole: member.role }
    AdminService.validateUserId(clubId, id, data).then(
      (result) => {
        this.props.history.push(`/clubs/${clubId}/admin`)
        window.location.reload();
      },
      (error) => {
        const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.setState({
        loading: false,
        message: resMessage
      });
    })
  }

  handleRowPointCalculationMethodClick(event, id) {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/admin/pointCalculationMethods/' + id )
    window.location.reload()
  }

  handleRowTournamentStructureClick(event, id) {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/admin/tournamentStructure/' + id )
    window.location.reload()
  }
  
  componentDidMount() {
    const clubId = this.props.match.params.clubId
    MemberService.getAllWithUserId(clubId).then((response) => {
      this.setState({ members: response.data.map(member => {
        return Object.assign({ baseValidated: member.validated, baseRole: member.role }, member)
      }) })
      AdminService.getAllPointCalculationMethods(clubId).then((response) => {
        this.setState({ pointCalculationMethods: response.data })
      })
      AdminService.getAllTournamentStructures(clubId).then((response) => {
        this.setState({ tournamentStructure: response.data })
      })
    })
  }

  render() {
    const { members, pointCalculationMethods, tournamentStructure }= this.state
    const clubId = this.props.match.params.clubId
    console.log(members)
    return (
      <div>
        Administration du club, montrer les utilisateurs associé au club et leur rôle. <br />
        Si ADMIN on ne peux pas modifier son role ^^<br />
        Ajouter aussi ici la gestion des calculs des points. <br />
        Cet onglet est réservé aux administrateur du club
        <h1>Membres avec un rôle</h1>
        <TableWithPagination
          columns={ membersColumns }
          rows= { members }
          onRowClick={ (e) => { } }
          rowDisplayMethod={(row, index) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_username`}>
                  { row.username }
                </TableCell>
                <TableCell key={`${row.id}_pseudo`}>
                  {  row.pseudo }
                </TableCell>
                <TableCell key={`${row.id}_role`}>
                  <InputSelect 
                    id="type"
                    label="Type"
                    required= { true }
                    value={ row.role || "" }
                    availableValues={ memberRolesSelectList }
                    onInputChange={(e) => this.handleChangeUserRole(e, index) }
                  />
                  <Button variant="contained" disabled={ row.baseRole === row.role || row.baseValidated === false } onClick={(e) => this.handleClickSaveUserRole(e, index) }>Enregistrer</Button>
                </TableCell>
                <TableCell key={`${row.id}_validated`}>
                  <InputCheckBox 
                    name={`${row.id}_validated`}
                    label="Validé"
                    checked={ row.validated }
                    onInputChange={(e) => this.handleValidateUserMember(e, index) }
                  />
                  <Button variant="contained" disabled={ row.baseValidated === row.validated } onClick={(e) => this.handleClickValidateUserMember(e, index) }>Enregistrer</Button>
                </TableCell>
              </Fragment>
            )
          }}
        />
        <h1>Méthodes de calcul des points</h1>
        <Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/admin/pointCalculationMethods/0`
    }}>
      Nouvelle méthode de calcul
    </Button>
        <TableWithPagination
          columns={ pointCalculationMethodsColumns }
          rows= { pointCalculationMethods }
          onRowClick={ this.handleRowPointCalculationMethodClick }
          rowDisplayMethod={(row, index) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_name`}>
                  { row.name }
                </TableCell>
                <TableCell key={`${row.id}_type`}>
                  {  row.type }
                </TableCell>
              </Fragment>
            )
          }}
        />
        <h1>Structures de tournois</h1>
        <Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/admin/tournamentStructure/0`
    }}>
      Nouvelle structure de tournoi
    </Button>
        <TableWithPagination
          columns={ tournamentStructureColumns }
          rows= { tournamentStructure }
          onRowClick={ this.handleRowTournamentStructureClick }
          rowDisplayMethod={(row, index) => {
            return (
              <Fragment>
                <TableCell key={`${row.id}_name`}>
                  { row.name }
                </TableCell>
                <TableCell key={`${row.id}_type`}>
                  {  row.type }
                </TableCell>
              </Fragment>
            )
          }}
        />
      </div>
    )
  }
}
