import React, { Component } from 'react'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'

export default class InputCheckBox extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onInputChange(e)
  }

  render() {
    const { checked, label, name } = this.props
    return (
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox checked={ checked || false } onChange={ this.handleChange } name={ name } />}
          label={ label }
        />
      </FormGroup>
    )
  }
}
