import API from './api'

const API_URL = `${process.env.REACT_APP_SERVICE_API_URL}/clubs/`

class TournamentService {

  prepare(clubId) {
    const apiUrl = API_URL + clubId + '/tournaments/prepare'
    return API.get(apiUrl);
  }

  getAll(clubId) {
    const apiUrl = API_URL + clubId + '/tournaments/'
    return API.get(apiUrl);
  }

  get(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id
    return API.get(apiUrl)
  }

  getWithRankingDetails(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/withRankingDetails'
    return API.get(apiUrl)
  }

  getWithStructure(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id
    return API.get(apiUrl)
  }

  create(clubId, data) {
    const apiUrl = API_URL + clubId + '/tournaments/'
    return API.post(apiUrl, data)
  }

  update(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id
    return API.put(apiUrl, data)
  }

  delete(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id
    return API.delete(apiUrl)
  }

  register(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/register'
    return API.post(apiUrl, {})
  }

  unregister(clubId, id) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/unregister'
    return API.post(apiUrl, {})
  }

  registerMembers(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/registerMembers'
    return API.post(apiUrl, data)
  }

  killMember(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/killMember'
    return API.post(apiUrl, data)
  }

  divideMembers(clubId, id, data) {
    const apiUrl = API_URL + clubId + '/tournaments/' + id + '/divide'
    return API.post(apiUrl, data)
  }
}

export default new TournamentService()