import React, { Component } from 'react'
import { Scheduler } from "@aldabil/react-scheduler";
import { fr } from 'date-fns/locale' ;

export default class ClubScheduler extends Component {

  constructor(props) {
    super(props)
  }

  render() {

    const { remoteEventsPromise, handleOnDelete } = this.props

    return (
      <div>
        Calendrier du club
        TODO Seul l'admin pourra ajouter/editer un event
        <Scheduler
        view="month"
        day={null}
        week={null}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6], 
          weekStartOn: 1, 
          startHour: 8, 
          endHour: 23,
          navigation: true
          }}
        locale={fr}
        deletable={ true }
        editable={false}
        onDelete={ handleOnDelete } 
        translations={{
          navigation: {
            today:"Aujourd'hui"
          }
        }}
        getRemoteEvents={ remoteEventsPromise }
        />
      </div>
    )
  }
}
