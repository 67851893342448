import React, { Component, Fragment } from 'react'
import TournamentService from '../services/tournamentService';
import { Link } from "react-router-dom";
import { Button, TableCell } from '@mui/material';
import AdminBlockquote from '../components/AdminBlockquote'
import TableWithPagination from '../components/TableWithPagination'
import Top3 from '../components/Top3';

import { dateTimeFormatOptions } from '../utils/dateUtils'
import AuthService from '../services/authService';


const columns = [
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'participantCount', headerName: 'Participants', width: 130 },
  { field: 'top3', headerName: 'TOP 3', width: 130 }
]

export default class ClubTournaments extends Component {
  constructor(props) {
    super(props)
    this.handleRowClick = this.handleRowClick.bind(this);
    this.state = {
      tournaments: []
    }
  }

  handleRowClick = (event, id) => {
    this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id )
    window.location.reload()
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    TournamentService.getAll(clubId).then((response) => {
      this.setState({ tournaments: response.data })
    })
  }
  render() {
    const clubId = this.props.match.params.clubId
    const isAdmin = AuthService.isClubAdmin(clubId)
    const addTournamentLink = (isAdmin)? (<Button component={Link} variant="contained" to={{
      pathname: `/clubs/${ clubId }/tournaments/edit`,
    }}>
      Nouveau tournoi
    </Button>) : null
    const { tournaments }= this.state
    return (
      <div>
        <AdminBlockquote clubId={ clubId } adminElements={(<Fragment>{ addTournamentLink }</Fragment>)} />
        <TableWithPagination
          columns={ columns }
          rows= { tournaments }
          onRowClick={ this.handleRowClick }
          cursorPointerOnHover={ true }
          rowDisplayMethod={(row) => {
            const date = new Date(row.date)
              return (
                <Fragment>
                  <TableCell key={`${row.id}_date`}>
                    {
                      new Intl.DateTimeFormat('fr-FR', dateTimeFormatOptions).format(date)
                    }
                  </TableCell>
                  <TableCell key={`${row.id}_member_count`}>
                    { row.memberCount }
                  </TableCell>
                  <TableCell key={`${row.id}_top3`}>
                    <Top3 top3={ row.top3 }/>
                  </TableCell>
                </Fragment>
              );
          }}
        />
      </div>
    )
  }
}
