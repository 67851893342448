import React, { Component } from 'react'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ClubCard from '../components/ClubCard'
import InputText from '../components/InputText';
import ClubService from '../services/clubService';

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.handleChangeClubName = this.handleChangeClubName.bind(this)
    this.handleCreateClub = this.handleCreateClub.bind(this)
    this.state = {
       clubs: [],
       clubName: '',
       loading: false,
       message: ''
    }
  }

  handleChangeClubName(e) {
    this.setState({
      clubName: e.target.value
    })
  }

  handleCreateClub(e) {
    e.preventDefault();
    this.setState({
      message: '',
      loading: true
    });
    const { clubName } = this.state
    ClubService.create({ name: clubName }).then(
      (result) => {
        this.props.history.push(`/clubs/${ result.data.id }`);
        window.location.reload();
      },
      (error) => {
        const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      this.setState({
        loading: false,
        message: resMessage
      });
    })
  }
  
  // TODO chercher la liste des clubs
  componentDidMount() {
    ClubService.getAll().then((response) => {
      this.setState({ clubs: response.data })
    })
  }

  render() {
    const clubCardList = this.state.clubs.map(club => (<Grid item md={4} sm={6} xs={12}><ClubCard key={ club.id } id={ club.id } name={ club.name }/></Grid>))
    return (
      <div>
        HOME PAGE
        <Grid container spacing={2}>
        { clubCardList }
        </Grid>
        <form onSubmit={ this.handleCreateClub }>
        <InputText
            required={ true }
            id="clubName"
            label="Club name"
            name="Club name"
            autoComplete="club name"
            autoFocus={ true }
            value={ this.state.clubName }
            onInputChange={ this.handleChangeClubName }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Create club
          </Button>
          {this.state.message && (
              <div className='form-group'>
                <div className='alert alert-danger' role='alert'>
                  {this.state.message}
                </div>
              </div>
            )}
        </form>
      </div>
    )
  }
}
