import React, { Component } from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FormControl } from '@mui/material';
import { withStyles } from '@mui/styles';

const useStyles = ((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

class InputSelect extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onInputChange(e)
  }

  render() {
    const { classes } = this.props;
    const { id, label, required, fullWidth, value, availableValues } = this.props
    const itemRequired = (required === false)? (<MenuItem value={ null }> <em>None</em> </MenuItem>) : null
    return (
        <FormControl 
          required={ required }
          variant="standard"
          className={classes.formControl}
          fullWidth= { fullWidth !== undefined ? fullWidth : true } >
        <InputLabel id={`label_${id}`}>{ label }</InputLabel>
        <Select
          labelId={`label_${id}`}
          id={ id }
          value={ value }
          onChange={ this.handleChange }
        >
          { itemRequired }
          {
            (availableValues || []).map((availableValue) => {
              const { value, text } = availableValue
              return (
                <MenuItem key={ value } value={ value }> { text } </MenuItem>    
              )
            })
          }
        </Select>
      </FormControl>
    )
  }
}

export default withStyles(useStyles)(InputSelect)
