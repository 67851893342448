import React, { Component } from 'react'
import Form from 'react-validation/build/form';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputDateTime from '../components/InputDateTime';
import InputNumber from '../components/InputNumber';
import InputSelect from '../components/InputSelect';
import RankingSelector from '../components/RankingSelector';

import TournamentService from '../services/tournamentService';

import { Icon } from '@iconify/react';
import deleteIcon from '@iconify-icons/mdi/delete';
import upIcon from '@iconify-icons/mdi/arrow-up';
import downIcon from '@iconify-icons/mdi/arrow-bottom';

const columns = [
  { field: 'rank', headerName: 'Place', width: 100 },
  { field: 'member', headerName: 'Membre', width: 130 },
  { field: 'eliminatedBy', headerName: 'Eliminé par', width: 130 } ,
  { field: 'points', headerName: 'Points', width: 150 },
  { field: 'actions', headerName: 'Actions', width: 300 }
]

export default class EditTournament extends Component {
  constructor(props) {
    super(props)
    this.onChangeDate = this.onChangeDate.bind(this);
    this.handleChangeRankings = this.handleChangeRankings.bind(this);
    this.onChangeMemberInfos = this.onChangeMemberInfos.bind(this);
    this.handleClickAddMember = this.handleClickAddMember.bind(this)
    this.handleEditTournament = this.handleEditTournament.bind(this)
    this.handleClickDeleteMember = this.handleClickDeleteMember.bind(this)
    this.handleClickUpMember = this.handleClickUpMember.bind(this)
    this.handleClickDownMember = this.handleClickDownMember.bind(this)
    const isNewTournament = (this.props.location.state || {}).id === undefined
    this.state = {
      isNewTournament,
      date: '',
      availableRankings: [],
      availableMembers: [],
      rankings: [],
      members: [],
      loading: false,
      message: ''
    }
  }

  onChangeDate(e) {
    this.setState({ date: e.target.value })
  }

  handleClickAddMember(e) {
    const members = this.state.members
    members.push({ rank: members.length + 1 })
    this.setState({ members })
  }

  handleChangeRankings(e, newValue) {
    this.setState({ rankings: newValue })
  }

  onChangeMemberInfos(e, index, field) {
    const members = this.state.members
    members[index][field] = e.target.value
    if (field === 'id') {
      members[index].pseudo = this.state.availableMembers.find(member => member.id === e.target.value).pseudo
      if (members[index].killerId === undefined) {
        members[index].killerId = null
      }
    }
    this.setState({ members })
  }

  handleClickDeleteMember(e, index) {
    const members = this.state.members
    members.splice(index, 1);
    this.setState({ members: members.map((member, index) => {
      return Object.assign(member, { rank: index + 1 })
    })})
  }

  handleClickUpMember(e, fromIndex) {
    if (fromIndex > 0) {
      this.moveMember(fromIndex, fromIndex - 1)
    }
  }

  handleClickDownMember(e, fromIndex) {
    const members = this.state.members
    if (fromIndex < members.length - 1) {
      this.moveMember(fromIndex, fromIndex + 1)
    }
  }

  moveMember(fromIndex, toIndex) {
    const members = this.state.members
    const member = members[fromIndex];
    members.splice(fromIndex, 1);
    members.splice(toIndex, 0, member);
    this.setState({ members })
  }

  handleEditTournament(e) {
    e.preventDefault()
    const { members, date, rankings } = this.state
    const rankingIds = rankings.map((ranking) => ranking.id)
    const clubId = this.props.match.params.clubId
    if(this.state.isNewTournament) {
      TournamentService.create(clubId, { date, rankingIds, members }).then((response) => {
        this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + response.data.newTournament.id )
        window.location.reload()
      })
    } else {
      const id = this.props.location.state.id
      TournamentService.update(clubId, id, { date, rankingIds, members }).then((response) => {
        this.props.history.push('/clubs/' + this.props.match.params.clubId + '/tournaments/' + id )
        window.location.reload()
      })
    }
  }

  findMembersList(currentMember) {
    return this.state.availableMembers
      .filter(member => this.state.members.find(m => m.id === member.id && m.id !== currentMember.id) === undefined)
      .map(member => Object.assign({ value: member.id, text: member.pseudo }))
  }

  findMembersKillerList(currentMember) {
    return this.state.members
      .filter(member => member.id !== currentMember.id && (member.rank === undefined || member.rank === null || member.rank < currentMember.rank))
      .map(member => Object.assign({ value: member.id || 'vide', text: member.pseudo }))
  }

  componentDidMount() {
    const clubId = this.props.match.params.clubId
    TournamentService.prepare(clubId).then((response) => {
      this.setState({ availableMembers: response.data.members })
      this.setState({
        availableRankings: response.data.rankings.map(ranking => { 
          return {
            id: ranking.id,
            name: ranking.name
          } 
        })
      })
      if(!this.state.isNewTournament) {
        const clubId = this.props.match.params.clubId
        const id = this.props.location.state.id
        TournamentService.get(clubId, id).then((response) => {
          this.setState({ date: new Date(response.data.tournament.date).toISOString().slice(0,16) })
          this.setState({ members: response.data.members })
          this.setState({ rankings: response.data.rankings.map(ranking => { 
            return {
              id: ranking.id,
              name: ranking.name
            } 
          }) })
        })
      }
    })
  }

  render() {
    const { availableRankings, date, rankings, members } = this.state
    return (
      <div>
        <Form onSubmit={ this.handleEditTournament } >
          <InputDateTime
            required={ true }
            id="date"
            label="Date"
            name="Date"
            autoComplete="date"
            autoFocus={ true }
            value={ date }
            onInputChange={ this.onChangeDate }
          />
          <RankingSelector 
             id="ranking-ids" values={ rankings } options={ availableRankings } onChange= { this.handleChangeRankings } />
          <Button variant="contained" onClick={ this.handleClickAddMember }>Ajouter un membre</Button>
          <Paper style={{ height: '80%', width: '100%', marginBottom: '10px', marginTop: '10px' }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.headerName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                { members.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={`member_${index}`}>
                    <TableCell>
                      <InputNumber
                        required={ false }
                        id={ `${index}_rank` }
                        type="number"
                        label="rank"
                        name="rank"
                        autoFocus={ false }
                        value={ row.rank || "" }
                        isOnlyInteger={ true }
                        isOnlyPositive={ true }
                        onInputChange={(e) => this.onChangeMemberInfos(e, index, "rank") }
                      />
                    </TableCell>
                    <TableCell>
                      <InputSelect 
                        id={ `${index}_member_id` }
                        label="member"
                        required= { true }
                        value={ row.id || "" }
                        availableValues={ this.findMembersList(row) }
                        onInputChange={(e) => this.onChangeMemberInfos(e, index, "id") }
                      />
                    </TableCell>
                    <TableCell>
                    <InputSelect 
                        id={ `${index}_killer` }
                        label="killer"
                        required= { false }
                        value={ row.killerId || "" }
                        availableValues={ this.findMembersKillerList(row) }
                        onInputChange={(e) => this.onChangeMemberInfos(e, index, "killerId") }
                      />
                    </TableCell>
                    <TableCell>
                    <InputNumber
                        required={ false }
                        id={ `${index}_points` }
                        type="number"
                        label="points"
                        name="points"
                        autoFocus={ false }
                        value={ row.points || "" }
                        onInputChange={(e) => this.onChangeMemberInfos(e, index, "points") }
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="Supprimer"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickDeleteMember(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ deleteIcon } style={{ fontSize: '18px'}} />
                      </IconButton>

                      <IconButton
                        aria-label="Monter"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickUpMember(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ upIcon } style={{ fontSize: '18px'}} />
                      </IconButton>

                      <IconButton
                        aria-label="Descendre"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => this.handleClickDownMember(e, index)}
                        color="inherit"
                      >
                        <Icon icon={ downIcon } style={{ fontSize: '18px'}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Validate
          </Button>
        </Form>
      </div>
    )
  }
}
